import { Mastercard, X, visa } from 'assets/images';
import { Button, Modal, Skeletons, Spinner, Tabs } from 'components';
import PaymentConfirmationModal from 'components/PaymentConfirmationModal';
import { useAddCartItem, useUpdateCartItem } from 'hooks';
import useMakePayment from 'hooks/useMakePayment';
import useSavedCards from 'hooks/useSavedCards';
import {
  dropdownActions,
  initialModalState,
} from 'pages/PhoneNumbers/constants';
import React, { useState, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  ANNUALLY,
  DAILY,
  MONTHLY,
  NG_CURRENCY_SYMBOL,
  formatPhoneNumber,
} from 'shared/resources';

const cardPlaceholderImage = {
  visa: visa,
  mastercard: Mastercard,
};

const PaymentMethodArray = [
  {
    label: 'New payment method',
    type: 'NEW_CARD',
  },
  {
    label: 'Pay with Saved cards',
    type: 'SAVED_CARD',
  },
  {
    label: 'Pay with Fonu wallet',
    type: 'WALLET',
  },
];

const CardItem = ({ data, setSelectedCard, selectedCard }) => {
  useEffect(() => {
    return () => {
      setSelectedCard('');
    };
  }, []);

  return (
    <div
      className="w-full flex flex-col gap-1 cursor-pointer"
      onClick={() => setSelectedCard(data?.uuid)}
    >
      <div className="flex items-center px-4 h-14  justify-between w-full bg-green-50 rounded-md border border-green-100">
        <div className="text-md flex gap-2 items-center ">
          <img
            src={cardPlaceholderImage[data?.card_type?.trim()?.toLowerCase()]}
            alt="MasterCard"
            className="w-8"
          />
          ****{data?.last4}
        </div>
        <div className="flex items-center gap-2">
          <input
            className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green cursor-pointer"
            type="radio"
            checked={data?.uuid === selectedCard}
            onChange={() => setSelectedCard(data?.uuid)}
          />
        </div>
      </div>
    </div>
  );
};

const NumberDetail = ({
  handleClose = () => null,
  selectedNumber,
  fromCart = false,
}) => {
  const { isPending, mutate: didCheckoutMutate } = useMakePayment({
    handleSuccess: () => {
      handleClose();
    },
  });
  const [planType, setPlanType] = useState(
    fromCart ? selectedNumber?.plan_type ?? MONTHLY : MONTHLY,
  );
  const { cardListing, isLoading } = useSavedCards();
  const [showModal, setShowModal] = useState(initialModalState);

  const { control, watch, handleSubmit, setValue } = useForm({
    defaultValues: {
      payment_type: 'NEW_CARD',
      is_saved_card: 'N',
      card_uuid: '',
    },
    mode: 'onChange',
  });

  const ModalLookup = useMemo(() => {
    return {
      [dropdownActions.PURCHASE_CONFIRMATION]: (
        <PaymentConfirmationModal
          handleClose={handleModalClose}
          continueCallBack={handleSubmit(onSubmit)}
        />
      ),
    };
  }, [showModal]);

  useEffect(() => {
    if (
      cardListing &&
      cardListing?.length > 0 &&
      watch('payment_type') === 'SAVED_CARD'
    ) {
      const defaultCard = cardListing?.find((item) => item?.is_default === 'Y');
      setValue('card_uuid', defaultCard?.uuid || '');
    }
  }, [cardListing?.length, watch('payment_type')]);

  function handleModalClose() {
    setShowModal(initialModalState);
  }

  const onSuccessFn = () => {
    handleClose();
  };
  const { mutateAsync: updateCartMutate, isPending: updateCartLoad } =
    useUpdateCartItem({ onSuccessFn });

  const { mutate: addCardMutate, isPending: addCardLoad } = useAddCartItem({
    onSuccessFn,
  });
  const handleAddToCart = async () => {
    if (fromCart) {
      const payload = {
        uuid: selectedNumber?.uuid,
        plan_type: planType,
        cost:
          planType === MONTHLY
            ? selectedNumber?.monthly_cost
            : selectedNumber?.yearly_cost,
      };
      await updateCartMutate(payload);
    } else {
      const payload = {
        did_uuid: selectedNumber?.uuid,
        did_number: selectedNumber?.did_number,
        cost:
          planType === MONTHLY
            ? selectedNumber?.monthly_cost
            : selectedNumber?.yearly_cost,
        plan_type: planType,
      };
      addCardMutate(payload);
    }
  };

  function onSubmit(values) {
    const did_uuids = [selectedNumber?.uuid];

    const payload = {
      did_uuids,
      payment_type: values?.payment_type, // NEW_CARD, SAVED_CARD, WALLET
      is_saved_card: values?.is_saved_card, // Y, N
      card_uuid: values?.card_uuid, // required if payment_type is SAVED_CARD
      plan_type: planType, // MONTHLY, ANNUALLY OR (empty)
      type: '', // APP or (empty)
    };

    didCheckoutMutate(payload);
  }

  const { isShow, modalType } = showModal;

  return (
    <div className="">
      <div className="flex justify-between items-start pt-4 px-4">
        <div className="flex flex-col gap-1">
          <div className="text-4xl font-semibold text-black">
            {formatPhoneNumber(selectedNumber?.did_number)}
          </div>
          <div className="text-sm  text-grey-700">
            You&apos;re about to purchase this Fonu number. Fonu number are
            subscription <br /> based. Select a payment plan below to continue
          </div>
        </div>
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className=" mt-8  mb-6 w-1/2 h-10 px-4">
        <Tabs
          height="h-10"
          onWhitebg={true}
          tabList={[
            {
              id: 1,
              label: 'Monthly',
              value: MONTHLY,
              onclick: () => setPlanType(MONTHLY),
            },
            {
              id: 2,
              label: 'Annual',
              value: ANNUALLY,
              onclick: () => setPlanType(ANNUALLY),
            },
            {
              id: 2,
              label: 'Daily',
              value: DAILY,
              onclick: () => setPlanType(DAILY),
            },
          ]}
          currentTab={planType}
        />
      </div>
      <div className="  px-4 pb-6 w-full xxl:max-h-full xl:max-h-full lg:max-h-full  md:overflow-auto md:max-h-56">
        <div className="text-4xl font-semibold">
          {NG_CURRENCY_SYMBOL}
          {planType === MONTHLY
            ? selectedNumber?.monthly_cost
            : selectedNumber?.yearly_cost}
          &nbsp;
          <span className="text-2xl text-grey-700 capitalize">
            {planType?.toLowerCase()}
          </span>
        </div>
        <div className="w-full flex flex-col  mt-6">
          <div className="flex flex-col gap-2">
            <div className="text-base font-semibold">Requirements</div>
            <span className="text-base text-grey-600 font-normal">
              Buying this number will require you to provide an address, and any
              form of <br /> Identity as one of the regulatory requirements for
              owning a Nigerian number.
            </span>
          </div>
          <div className="flex flex-col gap-2 mt-4">
            <div className="text-base font-semibold">Limitations</div>
            <span className="text-base text-grey-600 font-normal">
              The free version of this number would only be active for 10-days
            </span>
          </div>
          <div className="flex flex-col gap-2 mt-4">
            <div className="text-base font-semibold">Benefits</div>
            <div className="text-base text-grey-600 ml-3 flex gap-2 flex-col">
              <div className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-lg bg-grey-600"></div>Free{' '}
                {selectedNumber?.free_call} mins
              </div>
              <div className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-lg bg-grey-600"></div>Free 10
                SMS (Pay ₦4 per SMS after) Coming soon..
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full gap-5 mt-8 px-4">
        {PaymentMethodArray.map((item) => {
          return (
            <label
              htmlFor={`payment_type_${item?.type}`}
              key={item?.type}
              className="flex items-center gap-1 font-medium text-grey-800 cursor-pointer"
            >
              <Controller
                name={'payment_type'}
                control={control}
                render={({ field }) => {
                  return (
                    <input
                      className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                      type="radio"
                      {...field}
                      id={`payment_type_${item?.type}`}
                      value={item?.type}
                      checked={item?.type === watch('payment_type')}
                    />
                  );
                }}
              />
              {item?.label}
            </label>
          );
        })}
      </div>
      {watch('payment_type') === 'SAVED_CARD' ? (
        <div className="w-full px-4 mt-4 mb-3 gap-2 flex items-center max-w-[600px] overflow-auto flex-col max-h-28">
          {isLoading
            ? Array.from({ length: 3 }, (_, i) => i + 1).map((val) => {
                return (
                  <div className="w-full" key={val}>
                    <Skeletons height="h-14" />
                  </div>
                );
              })
            : cardListing?.length > 0
            ? cardListing?.map((card) => (
                <CardItem
                  key={card?.uuid}
                  data={card}
                  setSelectedCard={(val) => setValue('card_uuid', val)}
                  selectedCard={watch('card_uuid')}
                />
              ))
            : 'No saved cards'}
        </div>
      ) : null}
      {watch('payment_type') === 'NEW_CARD' ? (
        <div className="w-full px-4 mt-4 mb-10 gap-2 flex items-center">
          <input
            className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
            type="checkbox"
            id="is_saved_card"
            checked={watch('is_saved_card') === 'Y'}
            onChange={(e) => {
              setValue('is_saved_card', e.target.checked ? 'Y' : 'N');
            }}
          />
          <label className="cursor-pointer" htmlFor="is_saved_card">
            Do you want to save this card for future use
          </label>
        </div>
      ) : null}
      <div className="mt-4  flex items-center justify-end gap-2 px-4 pb-4">
        {!fromCart && (
          <Button
            width="w-[191px]"
            background="transparent"
            type="button"
            border="border-green"
            onClick={() => {
              setShowModal({
                modalType: dropdownActions.PURCHASE_CONFIRMATION,
                isShow: true,
                elementData: {},
              });
            }}
          >
            <div className="flex w-fit font-semibold  px-5 justify-center items-center text-green-400">
              {isPending ? <Spinner /> : 'Pay now'}
            </div>
          </Button>
        )}
        <Button width="w-[191px]" type="button" onClick={handleAddToCart}>
          <div className="flex w-fit px-5   justify-center items-center">
            {addCardLoad || updateCartLoad ? (
              <Spinner />
            ) : fromCart ? (
              'Update'
            ) : (
              'Add to cart'
            )}
          </div>
        </Button>
      </div>
      {isShow ? (
        <Modal
          handleClose={handleModalClose}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
};

export default NumberDetail;
