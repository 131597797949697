import { Mastercard, visa } from 'assets/images';
import { Button, Modal, Skeletons, Spinner } from 'components';
import PaymentConfirmationModal from 'components/PaymentConfirmationModal';
import useChangePlan from 'hooks/useChangePlan';
import useSavedCards from 'hooks/useSavedCards';
import { dropdownActions, initialModalState } from 'pages/Profile/constants';
import React, { useMemo, useState, useEffect } from 'react';

const cardPlaceholderImage = {
  visa: visa,
  mastercard: Mastercard,
};

const CardItem = ({ data, setSelectedCard, selectedCard }) => {
  useEffect(() => {
    return () => {
      setSelectedCard('');
    };
  }, []);

  return (
    <div
      className="w-full flex flex-col gap-1 cursor-pointer"
      onClick={() => setSelectedCard(data?.uuid)}
    >
      <div className="flex items-center px-4 h-14  justify-between w-full bg-green-50 rounded-md border border-green-100">
        <div className="text-md flex gap-2 items-center ">
          <img
            src={cardPlaceholderImage[data?.card_type?.trim()?.toLowerCase()]}
            alt="MasterCard"
            className="w-8"
          />
          ****{data?.last4}
        </div>
        <div className="flex items-center gap-2">
          <input
            className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green cursor-pointer"
            type="radio"
            checked={data?.uuid === selectedCard}
            onChange={() => setSelectedCard(data?.uuid)}
          />
        </div>
      </div>
    </div>
  );
};

const SavedCardListing = ({ planId = '' }) => {
  const [selectedCard, setSelectedCard] = useState('');
  const { isPending, mutate: changePlanMutate } = useChangePlan();
  const [showModalPayment, setShowModalPayment] = useState(initialModalState);

  const { data: savedCardData, isLoading: savedCardListingLoad } =
    useSavedCards();

  const savedCardsListing = useMemo(
    () => savedCardData?.data?.data || [],
    [savedCardData],
  );

  const ModalLookup = useMemo(() => {
    return {
      [dropdownActions.PURCHASE_CONFIRMATION]: (
        <PaymentConfirmationModal
          handleClose={handleModalClose}
          continueCallBack={handleSubmit}
        />
      ),
    };
  }, [showModalPayment]);

  useEffect(() => {
    if (savedCardsListing && savedCardsListing?.length > 0) {
      const defaultCard = savedCardsListing?.find(
        (item) => item?.is_default === 'Y',
      );
      setSelectedCard(defaultCard?.uuid || '');
    }
  }, [savedCardsListing?.length]);

  function handleModalClose() {
    setShowModalPayment(initialModalState);
  }

  function handleSubmit() {
    changePlanMutate({
      plan_uuid: planId,
      payment_type: 'SAVED_CARD',
      is_saved_card: 'N',
      card_uuid: selectedCard,
    });
  }
  const { isShow, modalType } = showModalPayment;

  return (
    <div className="max-w-[600px] w-full flex flex-col gap-2">
      {savedCardListingLoad
        ? Array.from({ length: 3 }, (_, i) => i + 1).map((val) => {
            return (
              <div className="w-full" key={val}>
                <Skeletons height="h-14" />
              </div>
            );
          })
        : savedCardsListing?.length > 0
        ? savedCardsListing?.map((card) => (
            <CardItem
              key={card?.uuid}
              data={card}
              setSelectedCard={setSelectedCard}
              selectedCard={selectedCard}
            />
          ))
        : 'No saved cards'}
      <div className="py-4 px-6">
        <Button
          extraClasses="mt-5"
          type="button"
          disabled={!selectedCard || isPending}
          onClick={() =>
            setShowModalPayment({
              modalType: dropdownActions.PURCHASE_CONFIRMATION,
              isShow: true,
              elementData: {},
            })
          }
        >
          {isPending ? <Spinner /> : 'Continue'}
        </Button>
      </div>
      {isShow ? (
        <Modal
          handleClose={handleModalClose}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
};

export default SavedCardListing;
