import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Card,
  Chevron,
  DotsNine,
  NotificationBell,
  Prohibit,
  SignOut,
  UserCircle,
} from 'assets/images';
import { Logo } from 'assets/images/signup';
import {
  ADMIN,
  MEMBER,
  NG_CURRENCY_SYMBOL,
  OWNER,
  navigationArr,
} from 'shared/resources';
import Dropdown from 'components/Dropdown';
import Modal from 'components/Modal';
import WelcomeFonuModal from 'pages/Dashboard/WelcomeFonuModal';
import { PhoneSystem } from 'layouts';
import Avatar from 'components/Avatar';
import { useAuth } from 'hooks/useAuth';
import { SipSocketContext } from 'hooks/useSip/SipSocketContext';
import Tooltip from 'components/Tooltip';
import { useWalletDetails } from 'hooks';

function handleSipStatus(_status) {
  if (_status === 'registered') {
    return (
      <Tooltip title="Connected" position="b">
        <span className="inline-flex h-3 w-3 rounded-full bg-green-400"></span>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Disconnected" position="b">
      <span className="inline-flex h-3 w-3 rounded-full bg-grey-500"></span>
    </Tooltip>
  );
}

function DropdownList({ item }) {
  const Icon = item?.icon;
  return (
    <div
      className="w-full flex  items-center gap-3 py-2 px-3 whitespace-nowrap"
      onClick={item?.onclick}
    >
      {Icon} {item?.title}
    </div>
  );
}

export default function Header() {
  const { user, clearUser } = useAuth();
  const userRole = user?.role_id;
  const [isDialpadOpen, openDialpad] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [sidebarList, setSidebarList] = useState([]);
  const { data: walletDetails } = useWalletDetails();
  const { socket = {} } = useContext(SipSocketContext);
  const searchParam = new URLSearchParams(window?.location?.search);
  const navigate = useNavigate();
  const showWelcomeModal = searchParam?.get('current') === 'from-base-origin';

  function handleCloseWelcomeModal() {
    if (window.location.pathname === '/') {
      navigate('/overview');
    } else {
      navigate(window.location.pathname);
    }
  }

  useEffect(() => {
    const result = navigationArr?.filter(({ access }) =>
      access?.includes(userRole),
    );
    setSidebarList(result);
  }, [userRole]);

  const { _status = '' } = socket;
  return (
    <>
      {user?.get_company_details?.plan_type === 'TRIAL' ? (
        <div className="bg-[#eed202]  py-5 flex h-4 items-center  justify-center border-top-grey xxl:px-7 xl:px-7 lg:px-7 md:px-7 sm:px-3 xs:px-3">
          You are using free trial. Remaining{' '}
          {user?.get_company_details?.remaining_days} day(s){' '}
          <span
            className="cursor-pointer underline italic"
            onClick={() => navigate('/profile/pnb')}
          >
            <b>Pay Now</b>
          </span>
        </div>
      ) : null}

      {user?.get_company_details?.remaining_days <=
        user?.get_settings?.plan_reminder_days &&
      user?.get_company_details?.plan_type === 'PURCHASE' ? (
        <div className="bg-[#eed202]  py-5 flex h-4 items-center  justify-center border-top-grey xxl:px-7 xl:px-7 lg:px-7 md:px-7 sm:px-3 xs:px-3">
          Hi, {user?.first_name ?? ''} you have{' '}
          {user?.get_company_details?.remaining_days} day(s) left before your
          plan expires.{' '}
          <span
            className="cursor-pointer underline italic"
            onClick={() => navigate('/profile/pnb')}
          >
            <b>Pay Now</b>
          </span>
        </div>
      ) : null}

      <div className="bg-black-600  py-5 flex items-center justify-between border-top-grey xxl:px-7 xl:px-7 lg:px-7 md:px-7 sm:px-3 xs:px-3">
        <div className=" flex text-base items-center xxl:gap-14 xl:gap-10 lg:gap-8 md:gap-6 sm:gap-8 xs:gap-6 xxl:justify-between xl:justify-start lg:justify-start md:justify-start sm:justify-between xs:justify-between   xxl:w-auto xl:w-auto lg:w-auto md:w-auto  sm:w-auto xs:w-[calc(100%_-_30px)]">
          <Logo className="w-20" />

          <div className="absolute top-5 right-0 flex items-center xxl:hidden  xl:hidden lg:hidden  md:hidden sm:block  xs:block ">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-white  "
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setShowDropdown((prev) => !prev)}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>

              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>

              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div
            className={`absolute bg-black-600 top-16 left-0 w-full p-5 flex-col xs:gap-4 sm:gap-6 z-10 ${
              showDropdown ? 'sm:flex xs:flex' : ' sm:hidden xs:hidden'
            } sm:ml-6`}
          >
            {sidebarList?.map((nav) => {
              const isActive = window.location.href.includes(nav?.parent);
              if (
                ['Teams', 'Phone Numbers', 'Calls', 'Overview'].includes(
                  nav?.label,
                )
              ) {
                return (
                  <div
                    key={nav?.id}
                    className="flex  items-center group relative  "
                    onClick={() => setShowDropdown((prev) => !prev)}
                  >
                    <NavLink
                      to={nav?.path}
                      className={
                        isActive
                          ? 'text-white xxl:text-base xl:text-base lg:text-sm md:text-xs font-medium cursor-pointer xs:text-green sm:border-none xs:border-b sm:text-white xs:border-green xs:w-full sm:w-auto xs:pb-4 sm:pb-0'
                          : 'text-grey-800 cursor-pointer xxl:text-base xl:text-base lg:text-sm md:text-xs group-hover:text-white sm:border-none xs:border-b xs:border-grey-100 xs:w-full sm:w-auto xs:pb-4 sm:pb-0'
                      }
                    >
                      {nav?.label}
                    </NavLink>
                    <div
                      className={`${
                        isActive
                          ? 'xs:invisible sm:visible'
                          : 'xs:invisible sm:invisible'
                      } group-hover:visible w-full h-0.5 bg-green rounded-sm absolute top-7`}
                    ></div>
                  </div>
                );
              }
            })}
          </div>

          <div className=" items-center gap-4 xxl:hidden  xl:hidden lg:hidden  md:hidden sm:flex  xs:flex">
            <div
              className="xs:hidden sm:flex   items-center gap-1 justify-center xs:h-8 xs:w-8 md:h-10 md:w-10	xs:text-white sm:text-black cursor-pointer sm:bg-white xs:bg-black-600 rounded-full relative"
              onClick={() => openDialpad(true)}
            >
              <DotsNine />
              <div className="absolute top-0 right-0">
                {handleSipStatus(_status)}
              </div>
            </div>

            <NotificationBell className="text-white" />
            <div className=" text-white  ">
              <Dropdown
                caretComponent={() => <></>}
                extraClassName=" bg--black-200"
                closeOnClickOutside={true}
                dropList={{
                  component: ({ item }) => {
                    if (
                      item?.access?.length === 0 ||
                      !item.access.includes(user?.role_id)
                    ) {
                      return;
                    }
                    return <DropdownList key={item?.id} item={item} />;
                  },
                  data: [
                    {
                      title: 'Profile',
                      value: 'profile',
                      onclick: () => navigate('/profile/general'),
                      icon: <UserCircle className="text-grey-700" />,
                      access: [ADMIN, OWNER, MEMBER],
                      id: 1,
                    },
                    {
                      title: 'Plan & billing',
                      value: 'planBilling',
                      onclick: () => navigate('/profile/pnb'),
                      icon: <Card className="text-grey-700" />,
                      access: [ADMIN, OWNER],
                      id: 2,
                    },
                    {
                      title: 'Blocklist',
                      value: 'blocklist',
                      onclick: () => navigate('/profile/blocklist'),
                      icon: <Prohibit className="text-grey-700" />,
                      access: [ADMIN, OWNER],
                      id: 3,
                    },
                    {
                      title: 'Logout',
                      value: 'logout',
                      onclick: clearUser,
                      icon: <SignOut className="text-grey-700" />,
                      access: [ADMIN, OWNER, MEMBER],
                      id: 4,
                    },
                  ],
                }}
                showcaret={true}
              >
                <div className="flex items-center gap-3 cursor-pointer">
                  <Avatar
                    name={`${user?.first_name} ${user?.last_name}`}
                    mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
                      user?.company_uuid || user?.uuid
                    }/profile/${user?.profile_pic}`}
                  />

                  <div className="flex flex-col gap-1">
                    <div className="flex items-center text-sm font-medium	 text-white">
                      {NG_CURRENCY_SYMBOL}
                      {walletDetails?.accountBalance || 0}
                    </div>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>

          <div className="flex items-center xxl:gap-8 xl:gap-6 lg:gap-6 md:gap-6  xxl:flex  xl:flex lg:flex  md:flex sm:hidden  xs:hidden">
            {sidebarList?.map((nav) => {
              const isActive = window.location.href.includes(nav?.parent);
              return (
                <div
                  key={nav?.id}
                  className="flex justify-center items-center group relative "
                >
                  <NavLink
                    to={nav?.path}
                    className={
                      isActive
                        ? 'text-white xxl:text-base xl:text-base lg:text-sm md:text-xs font-medium cursor-pointer'
                        : 'text-grey-800 cursor-pointer xxl:text-base xl:text-base lg:text-sm md:text-xs group-hover:text-white '
                    }
                  >
                    {nav?.label}
                  </NavLink>
                  <div
                    className={`${
                      isActive ? 'visible' : 'invisible'
                    } group-hover:visible w-3 h-0.5 bg-green rounded-sm absolute top-7`}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
        <div className=" items-center xxl:gap-6 xl:gap-4 lg:gap-3 md:gap-2 xxl:flex  xl:flex lg:flex  md:flex sm:hidden  xs:hidden">
          <div
            className="flex  items-center gap-1 justify-center w-auto h-auto 	 text-black cursor-pointer bg-white px-3 py-2  rounded-full"
            onClick={() => openDialpad(true)}
          >
            <DotsNine />
            <div className="xxl:text-base  xl:text-sm  lg:text-base  md:text-xs  flex font-medium  items-center gap-1">
              Dial pad {handleSipStatus(_status)}
            </div>
          </div>

          <NotificationBell className="text-white" />
          <div className="min-w-150 text-white  max-w-150">
            <Dropdown
              caretComponent={Chevron}
              extraClassName="min-w-150  max-w-150  bg--black-200"
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => {
                  if (
                    item?.access?.length === 0 ||
                    !item.access.includes(user?.role_id)
                  ) {
                    return;
                  }
                  return <DropdownList key={item?.id} item={item} />;
                },
                data: [
                  {
                    title: 'My account',
                    value: 'profile',
                    onclick: () => navigate('/profile/general'),
                    icon: <UserCircle className="text-grey-700 w-5 h-5" />,
                    access: [ADMIN, OWNER, MEMBER],
                    id: 1,
                  },
                  {
                    title: 'Plan & billing',
                    value: 'planBilling',
                    onclick: () => navigate('/profile/pnb'),
                    icon: <Card className="text-grey-700 w-[18px] h-[18px]" />,
                    access: [ADMIN, OWNER],
                    id: 2,
                  },
                  {
                    title: 'Blocklist',
                    value: 'blocklist',
                    onclick: () => navigate('/profile/blocklist'),
                    icon: <Prohibit className="text-grey-700 w-5 h-5" />,
                    access: [ADMIN, OWNER],
                    id: 3,
                  },
                  {
                    title: 'Sign out',
                    value: 'logout',
                    onclick: clearUser,
                    icon: <SignOut className="text-grey-700 w-5 h-5" />,
                    access: [ADMIN, OWNER, MEMBER],
                    id: 4,
                  },
                ],
              }}
              showcaret={true}
            >
              <div className="flex items-center gap-3 cursor-pointer">
                <Avatar
                  name={`${user?.first_name} ${user?.last_name}`}
                  mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
                    user?.company_uuid || user?.uuid
                  }/profile/${user?.profile_pic}`}
                />

                <div className="flex flex-col gap-1">
                  <div className="flex items-center text-sm font-medium	 text-white">
                    {NG_CURRENCY_SYMBOL}
                    {walletDetails?.accountBalance || 0}
                  </div>
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
        {showWelcomeModal ? (
          <Modal
            handleClose={() => handleCloseWelcomeModal()}
            headerComponent={null}
            footerComponent={null}
            shouldCloseOnClickOutside={true}
          >
            <WelcomeFonuModal
              handleCloseWelcomeModal={handleCloseWelcomeModal}
            />
          </Modal>
        ) : null}
        <PhoneSystem isDialpadOpen={isDialpadOpen} openDialpad={openDialpad} />
      </div>
    </>
  );
}
