import {
  X,
  MenuIcon,
  Mobile,
  NoRecord,
  PhoneCall,
  Trash,
  Edit,
  GroupUser,
  Refresh,
} from 'assets/images';
import {
  Avatar,
  Button,
  Dropdown,
  EllipsisTextWithTooltip,
  Modal,
  Spinner,
  TableManager,
  Tooltip,
} from 'components';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import AddMemberModal from './AddMemberModal';
import { dropdownActions, initialModalState } from './constants';
import AssignNumberModal from './AssignNumberModal';
import AssignTeamModal from './AssignTeamModal';
import DeleteMemberModal from './DeleteMemberModal';
import { getMemberListing } from 'api';
import {
  ADMIN,
  OWNER,
  formatPhoneNumber,
  roleIconLookup,
  rolesArray,
} from 'shared/resources';
import { useAuth } from 'hooks/useAuth';
import { useMemberDelete, useResendVerificationLink } from 'hooks';
import { SipSocketContext } from 'hooks/useSip/SipSocketContext';
import AvatarGroup from 'components/AvatarGroup';
import useBulkDelete from 'hooks/useBulkDelete';
import BulkDeleteMemberModal from './BulkDeleteMemberModal';

const BulkDeleteComponent = ({ data = [], callback = () => null }) => {
  const { mutate, isPending, status } = useBulkDelete();
  const [showModal, setShowModal] = useState(initialModalState);

  useEffect(() => {
    if (status && status === 'success') {
      callback();
    }
  }, [status]);

  const ModalLookup = {
    [dropdownActions.BULK_DELETE_MEMBER]: (
      <BulkDeleteMemberModal
        handleClose={callback}
        data={data}
        deleteFn={mutate}
        isLoading={isPending}
      />
    ),
  };
  const { isShow, modalType } = showModal;

  return (
    <div className="w-64">
      <Button
        height="h-10"
        type="button"
        background="danger"
        onClick={() =>
          setShowModal({
            isShow: true,
            modalType: dropdownActions.BULK_DELETE_MEMBER,
            elementData: {},
          })
        }
      >
        {isPending ? <Spinner /> : `Delete ${data?.length} member(s) -`}
      </Button>
      {isShow ? (
        <Modal
          handleClose={callback}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
};

const NoRecordItem = () => {
  return (
    <div className="w-full grid place-content-center text-grey-600 gap-3 ">
      <div className="flex items-center justify-center flex-col h-[calc(100vh_-_309px)]">
        <NoRecord />
        <span className="text-grey-600">
          You have no member added, add a new member now
        </span>
      </div>
    </div>
  );
};

const filterInitialValues = {
  filter: [
    {
      name: '',
      a_filter_type: '',
      placeholder: 'Enter name',
      type: 'text',
      shouldFilterOptionDisabled: false,
      label: 'Name',
      filterName: 'name',
      position: 1,
    },
  ],
};

const SepratorItem = ({ list = [], setShow = () => null }) => {
  const result =
    list?.map((option) => {
      return (
        <div key={option?.uuid} className="flex items-center gap-3 ">
          <Avatar name={option?.name} inGroup={true} />
          {option?.name}
        </div>
      );
    }) ?? null;

  return (
    <div>
      <div className="absolute right-1.5 top-1.5 ">
        <button type="button" onClick={() => setShow(false)}>
          <X className="text-white w-4 h-4" />
        </button>
      </div>

      <div className="flex flex-col gap-2 xxl:max-h-[330px] xl:max-h-[330px] lg:max-h-[330px] md:max-h-[234px]">
        {result}
      </div>
    </div>
  );
};

function Members() {
  const { socket = {} } = useContext(SipSocketContext);
  const { _makeCall = () => null } = socket;
  const [showModal, setShowModal] = useState(initialModalState);
  const { mutate, isPending: isDeleting } = useMemberDelete({
    onSuccessFn: () => handleCloseModal(),
    onErrorFn: () => null,
  });
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState([]);
  const { user = {} } = useAuth();
  const { mutate: resendVerificationMutate } = useResendVerificationLink();

  const handleDropListClick = ({ value = '' }, element = {}) => {
    if (value === dropdownActions.RESEND_INVITATION) {
      resendVerificationMutate({ uuid: element?.uuid });
      return;
    }
    if (value) {
      setShowModal({ isShow: true, modalType: value, elementData: element });
    }
  };

  function handleCloseModal() {
    setShowModal(initialModalState);
  }

  const handleMakeCall = (phone = '') => {
    if (phone) {
      _makeCall(user?.first_name, `${phone}`);
    }
  };

  const checkboxSelectableFn = (element) => {
    if ([OWNER].includes(user?.role_id)) {
      return true;
    }

    if ([ADMIN].includes(user?.role_id)) {
      if ([OWNER].includes(element?.role_id)) {
        return false;
      }
      return true;
    }
  };

  const tableActions = [
    {
      id: 1,
      label: 'Add member +',
      access: [OWNER, ADMIN]?.includes(user?.role_id),
      component: (
        <div className=" w-64">
          <Button
            height="h-10"
            type="button"
            onClick={() =>
              setShowModal({
                isShow: true,
                modalType: dropdownActions.UPSERT_MEMBER,
              })
            }
          >
            Add member +
          </Button>
        </div>
      ),
    },
  ];
  const ModalLookup = {
    [dropdownActions.UPSERT_MEMBER]: (
      <AddMemberModal showModal={showModal} handleClose={handleCloseModal} />
    ),
    [dropdownActions.ASSIGN_NUMBER]: (
      <AssignNumberModal handleClose={handleCloseModal} showModal={showModal} />
    ),
    [dropdownActions.ASSIGN_TEAM]: (
      <AssignTeamModal handleClose={handleCloseModal} showModal={showModal} />
    ),
    [dropdownActions.DELETE_MEMBER]: (
      <DeleteMemberModal
        showModal={showModal}
        handleClose={handleCloseModal}
        deleteFn={mutate}
        isLoading={isDeleting}
      />
    ),
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'check',
        header: (props) => {
          return (
            <input
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.table?.getIsAllRowsSelected()}
              onChange={props?.table?.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: (props) => {
          const element = props?.row?.original || {};
          const isCurrentUser = element?.uuid === user?.uuid;
          if (
            isCurrentUser ||
            !element?.company_uuid ||
            ([ADMIN].includes(user?.role_id) &&
              [OWNER].includes(element?.role_id))
          ) {
            return;
          }
          return (
            <input
              key={props?.row?.id}
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.row?.getIsSelected()}
              onChange={props?.row?.getToggleSelectedHandler()}
            />
          );
        },
        meta: {
          colType: 'action',
          width: '5%',
          hasAccess: [OWNER, ADMIN].includes(user?.role_id),
        },
      },
      {
        accessorKey: 'first_name',
        header: () => 'Name',
        cell: (props) => {
          const element = props?.row?.original || {};
          return (
            <span className="flex items-center gap-2">
              <Avatar
                name={`${element?.first_name} ${element?.last_name}`}
                mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
                  user?.company_uuid || user?.uuid
                }/profile/${element?.profile_pic}`}
              />
              <div className="flex flex-col items-start">
                <div className="flex items-center gap-2">
                  <EllipsisTextWithTooltip
                    string={`${element?.first_name} ${
                      element?.last_name ?? ''
                    }`}
                    charLength={25}
                    position="r"
                  />
                  <span>{element?.uuid === user?.uuid ? '(You)' : ''}</span>
                </div>
                <span className="font-light text-xs ">
                  {formatPhoneNumber(element?.phone)}
                </span>
              </div>
            </span>
          );
        },
        meta: {
          isSortable: true,
        },
      },
      {
        accessorKey: 'email',
        header: () => 'Email',
        cell: (props) => (
          <EllipsisTextWithTooltip
            string={props?.getValue()}
            charLength={30}
            position="r"
          />
        ),
        meta: {
          isSortable: true,
        },
      },
      {
        accessorKey: 'teams',
        header: () => 'Team',
        cell: (props) => {
          if (
            (props?.getValue() && props?.getValue()?.length === 0) ||
            !props?.getValue()
          ) {
            return '---';
          }
          return (
            <AvatarGroup
              id={props?.row?.id}
              options={props?.getValue()}
              accessorKey="name"
              sepratorComponent={SepratorItem}
            />
          );
        },
      },
      {
        accessorKey: 'caller_id',
        header: () => 'Phone number',
        cell: (props) => formatPhoneNumber(props?.getValue()),
      },
      {
        accessorKey: 'role_id',
        header: () => 'Role',
        cell: (props) => {
          return (
            <div className={`flex items-center  gap-1`}>
              {roleIconLookup[props?.getValue()] || null}
              {
                rolesArray?.find((role) => role.value === props?.getValue())
                  ?.label
              }
            </div>
          );
        },
      },
      {
        accessorKey: 'status',
        header: () => 'Status',
        cell: (props) => {
          return (
            <span className="flex items-center gap-2 text-secondary-light ">
              {props?.getValue() === 0 ? 'Pending' : 'Activated'}
            </span>
          );
        },
      },
      {
        accessorKey: 'action',
        header: () => '',
        cell: (props) => {
          const element = props?.row?.original || {};
          const isCurrentUser = element?.uuid === user?.uuid;
          const isStatusPending = element.status === 0;

          if (isCurrentUser) return;
          return (
            <div className="flex items-center gap-2">
              {isStatusPending ? null : (
                <Tooltip
                  key={element?.uuid}
                  title={`Call ${element?.first_name}`}
                  position="l"
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => handleMakeCall(element?.extension)}
                  >
                    <PhoneCall className="text-green" />
                  </div>
                </Tooltip>
              )}

              {[OWNER].includes(user?.role_id) ? (
                <Dropdown
                  menuWidth="w-max"
                  maxHeight={false}
                  closeOnClickOutside={true}
                  dropList={{
                    component: ({ item }) => {
                      const Icon = item?.icon ?? null;
                      const del = item?.type === 'del' ? 'text-danger' : '';

                      if (item.access) {
                        return (
                          <span
                            className={`w-full h-full flex  justify-start gap-2 items-center py-2 px-3 ${del}`}
                            onClick={() => handleDropListClick(item, element)}
                          >
                            {Icon} {item?.title}
                          </span>
                        );
                      }
                    },
                    data: [
                      {
                        id: 1,
                        title: 'Edit Member',
                        value: dropdownActions.UPSERT_MEMBER,
                        icon: <Edit className="text-grey-700" />,
                        access: isStatusPending ? false : true,
                      },
                      {
                        id: 2,
                        title: element?.caller_id
                          ? 'Unassign Number'
                          : 'Assign Number',
                        value: dropdownActions.ASSIGN_NUMBER,
                        icon: <Mobile className="text-grey-700" />,
                        access: isStatusPending ? false : true,
                      },
                      {
                        id: 3,
                        title: 'Assign Team',
                        value: dropdownActions.ASSIGN_TEAM,
                        access: isStatusPending ? false : true,
                        icon: (
                          <GroupUser
                            className="text-grey-700"
                            width={20}
                            height={20}
                          />
                        ),
                      },
                      {
                        id: 4,
                        title: 'Resend invitation',
                        value: dropdownActions.RESEND_INVITATION,
                        icon: <Refresh className="text-grey-700" />,
                        access: isStatusPending ? true : false,
                      },
                      {
                        id: 5,
                        title: 'Reset password',
                        value: dropdownActions.RESEND_INVITATION,
                        icon: <Refresh className="text-grey-700" />,
                        access: isStatusPending
                          ? false
                          : [OWNER].includes(user?.role_id)
                          ? true
                          : [ADMIN].includes(user?.role_id)
                          ? [OWNER].includes(element?.role_id)
                            ? false
                            : true
                          : false,
                      },
                      {
                        id: 6,
                        title: 'Delete Member',
                        value: dropdownActions.DELETE_MEMBER,
                        icon: <Trash className=" text-danger " />,
                        access: true,
                        type: 'del',
                      },
                    ],
                  }}
                  showcaret={false}
                >
                  <div className="cursor-pointer w-1">
                    <MenuIcon />
                  </div>
                </Dropdown>
              ) : [ADMIN].includes(user?.role_id) ? (
                [OWNER].includes(element?.role_id) ? null : (
                  <Dropdown
                    menuWidth="w-max"
                    maxHeight={false}
                    closeOnClickOutside={true}
                    dropList={{
                      component: ({ item }) => {
                        const Icon = item?.icon ?? null;
                        const del = item?.type === 'del' ? 'text-danger' : '';

                        if (item.access) {
                          return (
                            <span
                              className={`w-full h-full flex  justify-start gap-2 items-center py-2 px-3 ${del}`}
                              onClick={() => handleDropListClick(item, element)}
                            >
                              {Icon} {item?.title}
                            </span>
                          );
                        }
                      },
                      data: [
                        {
                          id: 1,
                          title: 'Edit Member',
                          value: dropdownActions.UPSERT_MEMBER,
                          icon: <Edit className="text-grey-700" />,
                          access: isStatusPending ? false : true,
                        },
                        {
                          id: 2,
                          title: element?.caller_id
                            ? 'Unassign Number'
                            : 'Assign Number',
                          value: dropdownActions.ASSIGN_NUMBER,
                          icon: <Mobile className="text-grey-700" />,
                          access: isStatusPending ? false : true,
                        },
                        {
                          id: 3,
                          title: 'Assign Team',
                          value: dropdownActions.ASSIGN_TEAM,
                          access: isStatusPending ? false : true,
                          icon: (
                            <GroupUser
                              className="text-grey-700"
                              width={20}
                              height={20}
                            />
                          ),
                        },
                        {
                          id: 4,
                          title: 'Resend invitation',
                          value: dropdownActions.RESEND_INVITATION,
                          icon: <Refresh className="text-grey-700" />,
                          access: isStatusPending ? true : false,
                        },
                        {
                          id: 5,
                          title: 'Reset password',
                          value: dropdownActions.RESEND_INVITATION,
                          icon: <Refresh className="text-grey-700" />,
                          access: isStatusPending
                            ? false
                            : [OWNER].includes(user?.role_id)
                            ? true
                            : [ADMIN].includes(user?.role_id)
                            ? [OWNER].includes(element?.role_id)
                              ? false
                              : true
                            : false,
                        },
                        {
                          id: 6,
                          title: 'Delete Member',
                          value: dropdownActions.DELETE_MEMBER,
                          icon: <Trash className=" text-danger " />,
                          access: true,
                          type: 'del',
                        },
                      ],
                    }}
                    showcaret={false}
                  >
                    <div className="cursor-pointer w-1">
                      <MenuIcon />
                    </div>
                  </Dropdown>
                )
              ) : null}
            </div>
          );
        },

        meta: {
          colType: 'action',
          width: '5%',
        },
      },
    ],
    [],
  );

  const bulkActions = [
    {
      id: 1,
      component: BulkDeleteComponent,
    },
  ];

  const { isShow, modalType } = showModal;

  return (
    <>
      <TableManager
        fetcherFn={getMemberListing}
        fetcherKey={'getMemberListing'}
        searchPlaceholder={'Search by name or email'}
        searchLabel="Manage all members in your workspace"
        isSearchable={true}
        shouldFilter={true}
        columns={columns}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        tableActions={tableActions}
        noRecordItem={NoRecordItem}
        filterInitialValues={filterInitialValues}
        setFilters={setFilters}
        filters={filters}
        bulkActions={bulkActions}
        checkboxSelectableFn={checkboxSelectableFn}
      />
      {isShow ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </>
  );
}

export default Members;
