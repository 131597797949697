import { X } from 'assets/images';
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Input } from 'components';
import { useNavigate, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getRequiredText } from 'shared/resources';
import moment from 'moment';

const numberRegex = /^[0-9]*$/;

const initialValues = {
  national_identity_number: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  dob: '',
};

const validationSchema = yup.object().shape({
  national_identity_number: yup.string().required(getRequiredText('NIN')),
  first_name: yup.string().required(getRequiredText('First name')),
  dob: yup
    .date()
    .max(new Date(), 'Date of birth must be in the past')
    .test(
      'is-over-18',
      'Date of birth must be before 18 years ago',
      function (value) {
        const dob = new Date(value);
        const today = new Date();
        const eighteenYearsAgo = new Date(
          today.getFullYear() - 18,
          today.getMonth(),
          today.getDate(),
        );
        return dob <= eighteenYearsAgo;
      },
    )
    .required(getRequiredText('Date of birth')),
});

const NationalIdentityModal = ({
  showModal = {},
  handleClose = () => null,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { elementData = {} } = showModal;
  const isEdit = elementData && Object.keys(elementData)?.length > 0;
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (isEdit) {
      reset(elementData);
    }
  }, [isEdit]);

  const onSubmit = async (values) => {
    navigate(`${pathname}/read-view?documentType=${showModal?.modalType}`, {
      state: { ...values, dob: moment(values?.dob).format('YYYY-MM-DD') },
    });
  };

  return (
    <div className="min-w-[500px]">
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            National identity number
          </div>
          <div className="text-sm  text-grey-700">
            Enter the information to add a valid document
          </div>
        </div>
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className=" pb-5 pt-1 ">
        <form className="overflow-auto px-6 ">
          <div className="flex flex-col gap-4">
            <div>
              <Controller
                name="national_identity_number"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="NIN"
                    error={errors?.national_identity_number?.message}
                    onChange={(e) => {
                      if (numberRegex.test(e.target.value)) {
                        return field.onChange(e.target.value);
                      }
                    }}
                    maxLength={50}
                    required={true}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="First name"
                    error={errors?.first_name?.message}
                    maxLength={50}
                    required={true}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="middle_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Middle name"
                    error={errors?.middle_name?.message}
                    maxLength={50}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Surname"
                    error={errors?.last_name?.message}
                    maxLength={50}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="dob"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      type="date"
                      label="Date of birth"
                      autoComplete="off"
                      max={
                        new Date(
                          new Date().getFullYear() - 18,
                          new Date().getMonth(),
                          new Date().getDate(),
                        )
                          .toISOString()
                          .split('T')[0]
                      }
                      error={errors?.dob?.message}
                      onWhitebg={true}
                      required
                    />
                  );
                }}
              />
            </div>
          </div>
        </form>
        <div className="px-6 mt-10">
          <Button type="button" onClick={handleSubmit(onSubmit)}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NationalIdentityModal;
