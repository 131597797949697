import { Button, Spinner } from 'components';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import IncomingCallSetting from './IncomingCallSetting';
import CallerIDSetting from './CallerIDSetting';
import PrivacyControlModal from './PrivacyControlModal';
import VoicemailSetting from './VoicemailSetting';
import AdvanceSettings from './AdvanceSettings';
import {
  advanceSettingsInitialValues,
  callerSettingsInitialValues,
  incomingCallInitialValues,
  privacySettingsInitialValues,
  privacySettingsValidationSchema,
  voicemailSettingsInitialValues,
  weekDays,
} from '../constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSetForwardingSettings, useUploadFile } from 'hooks';
import { toastEmitter } from 'components/Toast';
import { formatPhoneNumber } from 'shared/resources';

const normalizeEveryDayHours = (initialEveryDayHours) => {
  let custom_hours = {};
  for (let index = 0; index < weekDays.length; index++) {
    const dayOfWeek = weekDays[index];
    custom_hours[dayOfWeek] = {
      open: true,
      start: initialEveryDayHours?.start ?? '',
      end: initialEveryDayHours?.end ?? '',
    };
  }
  return custom_hours;
};
const normalizeWeekDayHours = (initialWeekDaysHour) => {
  let custom_hours = {};
  for (
    let index = 0;
    index < Object.keys(initialWeekDaysHour ?? {}).length;
    index++
  ) {
    const dayOfWeek = Object.keys(initialWeekDaysHour ?? {})[index];
    custom_hours[dayOfWeek] = {
      open: true,
      start: initialWeekDaysHour?.[dayOfWeek]?.start ?? '',
      end: initialWeekDaysHour?.[dayOfWeek]?.end ?? '',
    };
  }
  return custom_hours;
};
const normalizeCustomHours = (initialCustomHours) => {
  for (let obj in initialCustomHours) {
    if (!initialCustomHours[obj]?.open) {
      delete initialCustomHours[obj];
    }
  }
  return initialCustomHours;
};

const getCustomHours = (type = 'weekday', business_hours) => {
  if (!business_hours && !Object.keys(business_hours).length > 0) {
    return;
  }
  const initialEveryDayHours = business_hours?.every_day ?? {};
  const initialWeekDaysHour = business_hours?.week_days ?? {};
  const initialCustomHours = business_hours?.custom_hours ?? {};
  let custom_hours = {};
  switch (type) {
    case 'everyday':
      custom_hours = normalizeEveryDayHours(initialEveryDayHours);
      break;
    case 'weekday':
      custom_hours = normalizeWeekDayHours(initialWeekDaysHour);
      break;
    case 'custom':
      custom_hours = normalizeCustomHours(initialCustomHours);
      break;
  }
  return custom_hours;
};

const getCustomHoursWithAllKeys = (values) => {
  let newCustomHours = {};
  const allKeysData = privacySettingsInitialValues?.business_hour?.custom_hours;
  const allKeys = Object.keys(allKeysData);
  for (let index = 0; index < allKeys.length; index++) {
    const weekDay = allKeys[index];
    if (values?.[weekDay]) {
      newCustomHours[weekDay] = values?.[weekDay];
    } else {
      newCustomHours[weekDay] = {
        open: false,
        start: '',
        end: '',
      };
    }
  }
  return newCustomHours;
};

const SettingsModal = ({ showModal, handleClose = () => null }) => {
  const itemElement = showModal?.elementData ?? {};
  const [subSetting, setSubSetting] = useState('incoming_calls');
  const { isLoading: mediaUploadLoad, uploadMedia } = useUploadFile();
  const handleSuccess = () => {
    handleClose();
  };
  const { mutate: setForwardingSettings, isPending: setForwardingLoad } =
    useSetForwardingSettings({ handleSuccess });

  const {
    control: incomingCallControl,
    formState: { errors: incomingCallError },
    watch: incomingCallWatch,
    trigger: incomingCallTrigger,
    getValues: incominCallGetValues,
    reset: incomingCallReset,
  } = useForm({
    defaultValues: incomingCallInitialValues,
    mode: 'onChange',
  });

  const {
    control: callerSettingControl,
    formState: { errors: callerSettingError },
    watch: callerSettingWatch,
    trigger: callerSettingTrigger,
    getValues: callerSettingGetValues,
    reset: callerSettingReset,
  } = useForm({
    defaultValues: callerSettingsInitialValues,
    mode: 'onChange',
  });
  const {
    control: privacySettingControl,
    formState: { errors: privacySettingError },
    watch: privacySettingWatch,
    setValue: privacySettingSetValue,
    trigger: privacySettingTrigger,
    getValues: privacySettingGetValues,
  } = useForm({
    resolver: yupResolver(privacySettingsValidationSchema),
    defaultValues: privacySettingsInitialValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    control: voicemailSettingControl,
    formState: { errors: voicemailSettingError },
    watch: voicemailSettingWatch,
    setValue: voicemailSettingSetValue,
    trigger: voicemailSettingTrigger,
    getValues: voicemailSettingGetValues,
    reset: voicemailSettingReset,
  } = useForm({
    defaultValues: voicemailSettingsInitialValues,
    mode: 'onChange',
  });
  const {
    control: advanceSettingControl,
    formState: { errors: advanceSettingError },
    watch: advanceSettingWatch,
    setValue: advanceSettingSetValue,
    trigger: advanceSettingTrigger,
    getValues: advanceSettingGetValues,
    reset: advanceSettingReset,
  } = useForm({
    defaultValues: advanceSettingsInitialValues,
    mode: 'onChange',
  });
  useEffect(() => {
    if (itemElement) {
      if (itemElement?.settings) {
        if (itemElement?.settings?.incoming_call) {
          incomingCallReset(itemElement?.settings?.incoming_call);
        }
        if (itemElement?.settings?.caller_id) {
          callerSettingReset(itemElement?.settings?.caller_id);
        }
        if (itemElement?.settings?.voicemail) {
          voicemailSettingReset(itemElement?.settings?.voicemail);
        }
        if (itemElement?.settings?.advance) {
          advanceSettingReset(itemElement?.settings?.advance);
        }
        if (itemElement?.settings?.privacy_control) {
          const privacySettings = itemElement?.settings?.privacy_control;

          privacySettingSetValue(
            'ringtone_virtual_number',
            privacySettings?.ringtone_virtual_number,
          );
          privacySettingSetValue(
            'enable_business_hour',
            privacySettings?.business_hour_type === '24h' ? false : true,
          );
          if (privacySettings?.business_hour) {
            privacySettingSetValue(
              'business_hour.after_hours',
              privacySettings?.business_hour?.after_hours,
            );
            privacySettingSetValue(
              'business_hour.timezone',
              privacySettings?.business_hour?.timezone,
            );
            privacySettingSetValue(
              'business_hour.days',
              privacySettings?.business_hour_type,
            );

            switch (privacySettings?.business_hour_type) {
              case 'everyday':
                privacySettingSetValue('business_hour.every_day', {
                  start:
                    privacySettings?.business_hour?.custom_hours?.monday?.start,
                  end: privacySettings?.business_hour?.custom_hours?.monday
                    ?.end,
                });
                break;
              case 'weekday':
                privacySettingSetValue(
                  'business_hour.week_days',
                  privacySettings?.business_hour?.custom_hours,
                );
                break;
              case 'custom':
                privacySettingSetValue(
                  'business_hour.custom_hours',
                  getCustomHoursWithAllKeys(
                    privacySettings?.business_hour?.custom_hours,
                  ),
                );
                break;
            }
          }
        }
      }
    }
  }, [itemElement]);

  const SettingsArray = [
    {
      id: 1,
      label: 'Incoming calls',
      value: 'incoming_calls',
      isError: incomingCallError && Object.keys(incomingCallError)?.length > 0,
    },
    {
      id: 2,
      label: 'Caller ID',
      value: 'caller_id',
      isError:
        callerSettingError && Object.keys(callerSettingError)?.length > 0,
    },
    {
      id: 3,
      label: 'Privacy & Control',
      value: 'privacy_control',
      isError:
        privacySettingError && Object.keys(privacySettingError)?.length > 0,
    },
    {
      id: 4,
      label: 'Voicemail',
      value: 'voice_email',
      isError:
        voicemailSettingError && Object.keys(voicemailSettingError)?.length > 0,
    },
    {
      id: 5,
      label: 'Advance',
      value: 'advance',
      isError:
        advanceSettingError && Object.keys(advanceSettingError)?.length > 0,
    },
  ];
  const SubSettingComponentLookup = {
    incoming_calls: (
      <IncomingCallSetting
        control={incomingCallControl}
        errors={incomingCallError}
        watch={incomingCallWatch}
      />
    ),
    caller_id: (
      <CallerIDSetting
        control={callerSettingControl}
        errors={callerSettingError}
        watch={callerSettingWatch}
      />
    ),
    privacy_control: (
      <PrivacyControlModal
        control={privacySettingControl}
        errors={privacySettingError}
        watch={privacySettingWatch}
        setValue={privacySettingSetValue}
      />
    ),
    voice_email: (
      <VoicemailSetting
        control={voicemailSettingControl}
        errors={voicemailSettingError}
        watch={voicemailSettingWatch}
        setValue={voicemailSettingSetValue}
      />
    ),
    advance: (
      <AdvanceSettings
        control={advanceSettingControl}
        errors={advanceSettingError}
        watch={advanceSettingWatch}
        setValue={advanceSettingSetValue}
      />
    ),
  };

  const handleSettingSubmit = async (e) => {
    e.preventDefault();
    Promise.all([
      incomingCallTrigger(),
      callerSettingTrigger(),
      privacySettingTrigger(),
      voicemailSettingTrigger(),
      advanceSettingTrigger(),
    ]).then(async (val) => {
      if (val.every((val) => val)) {
        let incoming_call = incominCallGetValues();
        const caller_id = callerSettingGetValues();
        const privacy_control = privacySettingGetValues();
        const voicemail = voicemailSettingGetValues();
        const advance = advanceSettingGetValues();
        if (incoming_call?.icon && typeof incoming_call?.icon !== 'string') {
          try {
            const res = await uploadMedia({
              type: 'icon',
              file: incoming_call?.icon,
            });
            if (res) {
              incoming_call.icon = res?.file_name;
            } else {
              return;
            }
          } catch (err) {
            return toastEmitter('error', 'Unable to upload icon.');
          }
        }

        const payload = {
          uuid: itemElement?.uuid,
          settings: {
            incoming_call: {
              ...incoming_call,
              auto_record_calls: incoming_call?.auto_record_calls,
            },
            caller_id,
            advance,
            voicemail,
            privacy_control: {
              ringtone_virtual_number: privacy_control?.ringtone_virtual_number,
              business_hour_type: privacy_control?.enable_business_hour
                ? privacy_control?.business_hour?.days
                : '24h',
            },
          },
        };
        if (privacy_control?.enable_business_hour) {
          payload.settings.privacy_control.business_hour = {
            timezone: privacy_control?.business_hour?.timezone,
            after_hours: privacy_control?.business_hour?.after_hours,
            custom_hours: getCustomHours(
              privacy_control?.business_hour?.days,
              privacy_control?.business_hour,
            ),
          };
        }
        setForwardingSettings(payload);
      }
    });
  };

  const handleChangeSubSettingModal = (newSubSetting = '') => {
    setSubSetting(newSubSetting);
    switch (subSetting) {
      case 'incoming_calls':
        incomingCallTrigger();
        break;
      case 'caller_id':
        callerSettingTrigger();
        break;
      case 'privacy_control':
        privacySettingTrigger();
        break;
      case 'voice_email':
        voicemailSettingTrigger();
        break;
      case 'advance':
        advanceSettingTrigger();
        break;
      default:
        break;
    }
  };
  return (
    <form
      className="w-[640px]"
      onSubmit={(e) => handleSettingSubmit(e, subSetting)}
    >
      <div className="flex justify-between items-start px-6 pt-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            {formatPhoneNumber(itemElement?.did_number)}
          </div>
          <div className="text-sm  text-grey-700">
            Settings related to this phone number
          </div>
        </div>
      </div>
      <div className="flex items-center w-full border-b-2 border-grey-300 gap-4  justify-between px-6 mt-7">
        {SettingsArray.map((setting) => {
          const isActive = setting?.value === subSetting;
          return (
            <div
              key={setting?.id}
              className="flex justify-center items-center group relative"
              onClick={() => handleChangeSubSettingModal(setting?.value)}
            >
              <div
                className={
                  isActive
                    ? 'text-grey-900 text-base font-semibold cursor-pointer mb-2'
                    : setting?.isError
                    ? 'text-danger cursor-pointer text-base font-medium group-hover:text-grey-900 mb-2 '
                    : 'text-grey-600 cursor-pointer text-base font-medium group-hover:text-grey-900 mb-2 '
                }
              >
                {setting?.label}
              </div>
              <div
                className={`${
                  isActive ? 'visible' : 'invisible'
                } group-hover:visible w-6 h-0.5 bg-grey-900 rounded-sm absolute top-8`}
              ></div>
            </div>
          );
        })}
      </div>

      <div className="px-7 mt-7 mb-2 overflow-auto xxl:h-[calc(100vh_-_244px)] xl:h-[calc(100vh_-_233px)] lg:h-[calc(100vh_-_244px)] md:h-[calc(100vh_-_228px)] ">
        {SubSettingComponentLookup[subSetting]}
      </div>
      <div className="flex justify-between gap-2 px-6 ">
        <Button
          type="submit"
          height="xxl:h-[54px] xl:h-[45px] lg:h-[45px] md:h-[41px]"
        >
          {setForwardingLoad || mediaUploadLoad ? <Spinner /> : 'Save settings'}
        </Button>
      </div>
    </form>
  );
};

export default SettingsModal;
