import { callsHistory } from 'api';
import {
  Button,
  Modal,
  PlayAudioModal,
  Spinner,
  TableManager,
  Tooltip,
} from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ADMIN,
  OWNER,
  formatDateTime,
  formatPhoneNumber,
} from 'shared/resources';
import { dropdownActions, initialModalState } from './constants';
import { MissedCall, PhoneIncoming, PhoneOutgoing, Play } from 'assets/images';
import useBulkDelete from 'hooks/useBulkDelete';
import { useAuth } from 'hooks/useAuth';
import GetFonuNumberName from 'hooks/GetFonuNumberName';
import GetMemberName from 'hooks/GetMemberName';
import BulkDeleteCallsModal from './BulkDeleteCallsModal';

const BulkDeleteComponent = ({ data = [], callback = () => null }) => {
  const { mutate, isPending, status } = useBulkDelete();
  const [showModal, setShowModal] = useState(initialModalState);

  useEffect(() => {
    if (status && status === 'success') {
      callback();
    }
  }, [status]);

  const ModalLookup = {
    [dropdownActions.BULK_DELETE_CALLS]: (
      <BulkDeleteCallsModal
        handleClose={callback}
        data={data}
        deleteFn={mutate}
        isLoading={isPending}
      />
    ),
  };
  const { isShow, modalType } = showModal;

  return (
    <div className="w-80">
      <Button
        height="h-10"
        type="button"
        background="danger"
        onClick={() =>
          setShowModal({
            isShow: true,
            modalType: dropdownActions.BULK_DELETE_CALLS,
            elementData: {},
          })
        }
      >
        {isPending ? <Spinner /> : `Delete ${data?.length} record(s) -`}
      </Button>
      {isShow ? (
        <Modal
          handleClose={callback}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
};

const filterInitialValues = {
  filter: [
    {
      name: '',
      a_filter_type: '',
      placeholder: 'Enter name',
      type: 'text',
      shouldFilterOptionDisabled: false,
      label: 'Name',
      filterName: 'name',
      position: 1,
    },

    {
      dob: 'date',
      a_filter_type: '',
      placeholder: 'Select date',
      type: 'date-input',
      shouldFilterOptionDisabled: true,
      label: 'Date',
      filterName: 'date',
      position: 3,
    },
  ],
};

function Calls() {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(initialModalState);
  const [filters, setFilters] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [tab, setTab] = useState('ALL');

  const callDirectionIconLookup = useMemo(() => {
    return {
      inbound: (
        <Tooltip title="Incoming call">
          <PhoneIncoming className="text-green-400" />
        </Tooltip>
      ),
      missed: (
        <Tooltip title="Missed call">
          <MissedCall className="text-danger" />
        </Tooltip>
      ),
      outbound: (
        <Tooltip title="Outgoing call">
          <PhoneOutgoing className="text-green" />
        </Tooltip>
      ),
    };
  }, []);

  const checkboxSelectableFn = () => {
    return true;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'check',
        header: (props) => {
          return (
            <input
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.table?.getIsAllRowsSelected()}
              onChange={props?.table?.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: (props) => {
          return (
            <input
              key={props?.row?.id}
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.row?.getIsSelected()}
              onChange={props?.row?.getToggleSelectedHandler()}
            />
          );
        },
        meta: {
          colType: 'action',
          width: '5%',
          hasAccess: [OWNER, ADMIN].includes(user?.role_id),
        },
      },
      {
        accessorKey: 'start_stamp',
        header: () => 'Date',
        cell: (props) => {
          const [date, time] = formatDateTime(props?.getValue());
          return (
            <span>
              {date}&nbsp;&#183;&nbsp;{time}
            </span>
          );
        },
      },
      {
        accessorKey: 'caller_id_number',
        header: () => 'From',
        cell: (props) => {
          return formatPhoneNumber(`+${props?.getValue()}`);
        },
      },
      {
        accessorKey: 'xml_cdr_uuid',
        header: () => 'To',
        cell: (props) => {
          const element = props?.row?.original;
          const isOutboundCall = element?.direction === 'Outbound';

          return (
            <div className="flex items-start gap-1 ">
              {callDirectionIconLookup[element?.direction?.toLowerCase()]}
              <div className="flex flex-col">
                <div>
                  <GetFonuNumberName
                    id={
                      isOutboundCall
                        ? element?.destination_number
                        : element?.caller_destination
                    }
                  />
                </div>
                <div className="text-grey-600">
                  {formatPhoneNumber(
                    isOutboundCall
                      ? element?.destination_number
                      : element?.caller_destination,
                  )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: 'accountcode',
        header: () => 'Member',
        cell: (props) => {
          return (
            <div className="flex items-start gap-1 ">
              <div className="flex flex-col">
                <div>
                  {props.row.original?.forward_type === 'EXTENSION' ? (
                    <GetMemberName id={props.row.original?.forward_value} />
                  ) : (
                    <GetMemberName id={props.row.original?.extension} />
                  )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: 'duration',
        header: () => 'Duration',
      },

      {
        accessorKey: 'status',
        header: () => 'Status',
        cell: (props) => {
          return (
            <span className="capitalize">
              {props?.getValue()?.toLowerCase()?.split('_')?.join(' ')}
            </span>
          );
        },
        meta: {
          width: '5%',
        },
      },
      {
        accessorKey: 'action',
        header: () => '',
        cell: (props) => {
          const element = props?.row?.original || {};
          const isAction = props?.row?.original?.recording_file ?? false;
          if (!isAction) return;
          return (
            <div className="flex gap-3">
              <span
                className="text-green cursor-pointer"
                onClick={() => {
                  setShowModal({
                    isShow: true,
                    modalType: dropdownActions.PLAY_GREETING,
                    elementData: element,
                    type: 'center',
                  });
                }}
              >
                <Play className="w-6 h-6" />
              </span>
            </div>
          );
        },
        meta: {
          colType: 'action',
          width: '5%',
        },
      },
    ],
    [],
  );

  function handleCloseModal() {
    setShowModal(initialModalState);
  }

  const bulkActions = [
    {
      id: 1,
      component: BulkDeleteComponent,
    },
  ];
  const { isShow, type } = showModal;
  return (
    <>
      <TableManager
        {...{
          fetcherKey: 'callsHistory',
          fetcherFn: callsHistory,
          columns,
          showPagination: true,
          isSearchable: true,
          bulkActionsAccKey: 'xml_cdr_uuid',
          tabList: [
            {
              id: 1,
              label: 'All',
              onclick: () => setTab('ALL'),
              value: 'ALL',
            },
            {
              id: 2,
              label: 'Outgoing',
              onclick: () => setTab('OUTGOING'),
              value: 'OUTGOING',
            },
            {
              id: 3,
              label: 'Incoming',
              onclick: () => setTab('INCOMING'),
              value: 'INCOMING',
            },
            {
              id: 4,
              label: 'Missed',
              onclick: () => setTab('MISSED'),
              value: 'MISSED',
            },
          ],
          currentTab: tab,
          searchLabel: 'Manage call history in your workspace',
          searchPlaceholder: 'Search with number..',
          filters,
          setFilters,
          openFilter,
          setOpenFilter,
          filterInitialValues,
          bulkActions,
          checkboxSelectableFn,
          extraParams: {
            request_type: 'history-list',
            type: tab,
          },
        }}
      />
      {isShow && type === 'center' ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          <PlayAudioModal
            type="recording"
            fileNameAccessorKey={'recording_file'}
            showModal={showModal}
            handleClose={handleCloseModal}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default Calls;
