import { X } from 'assets/images';
import { Button, Spinner } from 'components';
import { toastEmitter } from 'components/Toast';
import { useAuth } from 'hooks/useAuth';
import useChangePlan from 'hooks/useChangePlan';
import React from 'react';

const Item = ({ item, bg, textCenter }) => {
  const ifTypeBoolean = item?.type === 'boolean';

  if (ifTypeBoolean) {
    return (
      <div
        className={`border-b border-grey-400 text-sm p-3 flex  ${
          textCenter ? 'justify-center' : 'justify-start'
        } `}
      >
        {item?.value ? (
          <X
            className={`w-4 ${
              bg === 'bg-green' ? 'text-white' : 'text-grey-900'
            }`}
          />
        ) : (
          <>&nbsp;</>
        )}
      </div>
    );
  }

  return (
    <div
      className={`border-b border-grey-400 text-sm p-3 flex  ${
        textCenter ? 'justify-center' : 'justify-start'
      } `}
    >
      {item?.value ? (
        <span
          className={`${bg === 'bg-green' ? 'text-white' : 'text-grey-900'}`}
        >
          {item?.value === '32767' ? 'Unlimited' : item?.value}
        </span>
      ) : (
        <>&nbsp;</>
      )}
    </div>
  );
};

const PricingItem = ({
  isActivePlan = false,
  width = 'w-[30%]',
  title = 'Basic',
  headerBg = 'bg-white',
  bg = 'bg-white',
  highiLight = 'bg-black-600',
  itemsArr = [],
  textCenter = true,
  withActionBtn = true,
  planId,
  handleChangePlan = () => {},
  handleViewPlan = () => {},
  action = '',
}) => {
  const { isPending } = useChangePlan();
  const { user } = useAuth();

  function changePlan(planId, action = '') {
    handleChangePlan(planId, action);

    // mutate({
    //   plan_uuid: planId,
    // });
  }
  return (
    <div className={`border border-grey-400 rounded ${width} overflow-hidden`}>
      <div className={`${highiLight} h-1`}></div>
      <div
        className={`h-32 ${headerBg} ${
          bg === 'bg-green' ? 'text-white' : 'text-grey-900'
        }  text-2xl flex items-center justify-center border-b border-grey-400`}
      >
        {title}
      </div>
      <div className={`${bg}`}>
        {itemsArr?.map((item) => (
          <Item item={item} key={item?.key} bg={bg} textCenter={textCenter} />
        ))}
      </div>
      <div className={`${bg}  h-16 flex items-center justify-center`}>
        {withActionBtn ? (
          user?.get_company_details?.remaining_days <=
            user?.get_settings?.plan_reminder_days &&
          user?.get_company_details?.plan_type === 'PURCHASED' ? (
            <Button
              onClick={() => {
                action === 'Downgrade'
                  ? handleViewPlan({
                      currentPlan: user?.get_company_details.plan_uuid,
                      newPlan: planId,
                    })
                  : changePlan(planId, 'Renew');
              }}
              disabled={isPending}
              type="button"
              height="h-3/4"
              width="w-1/2"
              background={`${bg === 'bg-green' ? 'white' : 'green'}`}
              color={`${bg === 'bg-green' ? 'text-green' : 'text-white'}`}
            >
              {isPending ? <Spinner /> : isActivePlan ? 'Renew' : 'Buy'}
            </Button>
          ) : (
            <Button
              onClick={() => {
                if (
                  isActivePlan &&
                  user?.get_company_details?.plan_type !== 'TRIAL'
                ) {
                  return toastEmitter(
                    'success',
                    'You are already on this plan.',
                  );
                }

                action === 'Downgrade'
                  ? handleViewPlan({
                      currentPlan: user?.get_company_details.plan_uuid,
                      newPlan: planId,
                    })
                  : changePlan(planId, 'Buy');
              }}
              disabled={isPending}
              type="button"
              height="h-3/4"
              width="w-1/2"
              background={`${bg === 'bg-green' ? 'white' : 'green'}`}
              color={`${bg === 'bg-green' ? 'text-green' : 'text-white'}`}
            >
              {isPending ? (
                <Spinner />
              ) : isActivePlan &&
                user?.get_company_details?.plan_type !== 'TRIAL' ? (
                'Current Plan'
              ) : (
                'Buy'
              )}
            </Button>
          )
        ) : null}
      </div>
    </div>
  );
};

export default PricingItem;
