import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { SipSocketContext } from 'hooks/useSip/SipSocketContext';
import { useDidList } from 'hooks';
import {
  BlackAdd,
  BlackHold,
  BlackKeypad,
  BlackMute,
  BlackRecord,
  BlackTransfer,
  CutCall,
  WhiteHold,
  WhiteMute,
  WhiteRecord,
} from 'assets/images';

const ConnectedScreen = ({ activeCallData = {}, setAction }) => {
  const { socket = {} } = useContext(SipSocketContext);
  const { user } = useAuth();
  const { mutate, data } = useDidList();
  const listDID = data?.data?.data || [];
  const {
    _number = '',
    _status = '',
    _direction = '',
    callID = '',
  } = activeCallData;
  const { _terminate, _muteCall, _toggleHold, _uaSessions, _sendDtmf } = socket;

  const requiredObj =
    listDID &&
    listDID.length > 0 &&
    listDID?.filter((d) => d.did_number === user?.caller_id)?.[0]?.[
      'outgoing_call_recording'
    ]
      ? 'Y'
      : 'N';

  const isUARecordingEnabled =
    _direction === 'inbound'
      ? !!_uaSessions?.[callID]?._request?.headers?.['X-Record']
      : requiredObj === 'Y';

  const [isRecording, setIsRecording] = useState(isUARecordingEnabled);

  useEffect(() => {
    mutate();
  }, []);

  const callRecording = (uuid) => {
    if (_number?.length <= 4) return;
    if (isRecording) {
      _sendDtmf(uuid, `*3`);
      setIsRecording(false);
    } else {
      _sendDtmf(uuid, `*2`);
      setIsRecording(true);
    }
  };
  return (
    <div className="flex flex-col justify-around h-full xxl:gap-8 xl:gap-4 lg:gap-8 md:gap-10 xxl:px-8 xl:px-7 lg:px-8 md:px-7 xxl:mt-8 xl:mt-3 lg:mt-8 md:mt-8 xxl:min-h-[471px] xxl:max-h-[471px] xl:min-h-[375px] xl:max-h-[375px]">
      <div className="flex flex-col gap-8">
        <div className="flex items-center justify-between xxl:px-5 xl:px-3 lg:px-5 md:px-8">
          <div
            className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px] "
            onClick={() => _muteCall(callID)}
          >
            {_status === 'mute' ? (
              <WhiteMute
                height={64}
                className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer"
              />
            ) : (
              <BlackMute className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
            )}

            <div className="text-sm text-white">
              {_status === 'mute' ? 'Unmute' : 'Mute'}
            </div>
          </div>
          <div
            className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
            onClick={() => setAction('transfer')}
          >
            <BlackTransfer className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
            <div className="text-sm text-white">Transfer</div>
          </div>
          <div
            className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
            onClick={() => setAction('dial')}
          >
            <BlackKeypad className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
            <div className="text-sm text-white">Keypad</div>
          </div>
        </div>
        <div className="flex items-center justify-between xxl:px-5 xl:px-3 lg:px-5 md:px-8">
          <div
            className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
            onClick={() => _toggleHold(callID)}
          >
            {_status === 'hold' ? (
              <WhiteHold className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
            ) : (
              <BlackHold className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
            )}
            <div className="text-sm text-white">
              {_status === 'hold' ? 'Unhold' : 'Hold'}
            </div>
          </div>
          <div
            className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
            onClick={() => setAction('add')}
          >
            <BlackAdd className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
            <div className="text-sm text-white">Add Call</div>
          </div>
          <div
            className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
            onClick={() => callRecording(callID)}
          >
            {_number?.length <= 4 ? (
              <BlackRecord className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
            ) : isRecording ? (
              <WhiteRecord className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
            ) : (
              <BlackRecord className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
            )}

            <div className="text-sm text-white">Record</div>
          </div>
        </div>
      </div>
      <div
        className="flex items-center flex-col gap-2 justify-center "
        onClick={() => {
          _terminate(callID);
        }}
      >
        <CutCall className="xxl:w-auto xl:w-16 lg:w-16 md:w-14 cursor-pointer" />
      </div>
    </div>
  );
};

export default ConnectedScreen;
