import {
  CountrySelect,
  CustomAudioPlayer,
  Dropdown,
  Modal,
  Skeletons,
} from 'components';
import CustomSelect from 'components/CustomSelect';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import EveryDayHoursForm from './EveryDayHoursForm';
import WeekdaysHourForm from './WeekdaysHourForm';
import CustomHourForm from './CustomHourForm';
import { initialModalState } from 'pages/PhoneNumbers/constants';
import AddVoicemailModal from '../AddVoicemailModal';
import ListManager from 'components/ListManager';
import { greetingList } from 'api';
import { useAuth } from 'hooks/useAuth';
import { LoadingItem } from 'pages/Dashboard';
import { useQueryClient } from '@tanstack/react-query';
import { Download, MenuIcon, Trash } from 'assets/images';
import DeleteGreetingModal from 'pages/Calls/Greetings/DeleteGreetingModal';
import { dropdownActions } from '../../VoicemailSetting/constants';
import { useDeleteGreeting } from 'hooks';
import { downloadFile } from 'shared/resources';

const daysArray = [
  {
    id: 1,
    label: 'Every day',
    value: 'everyday',
  },
  {
    id: 2,
    label: 'Weekdays',
    value: 'weekday',
  },
  {
    id: 3,
    label: 'Custom',
    value: 'custom',
  },
];
// const TimezoneItem = ({ item }) => {
//   return (
//     <div
//       key={item.value}
//       value={item?.value}
//       className="w-full h-full flex  justify-start gap-2 items-center"
//     >
//       {item?.label}
//     </div>
//   );
// };
const DayItemComponent = ({ item }) => {
  return (
    <div
      key={item.value}
      value={item?.value}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.label}
    </div>
  );
};

const LoadingPlayer = () => {
  return (
    <div className="w-full flex justify-between gap-4 ">
      <div className="flex gap-4 items-center">
        <span className=" w-12 h-12 flex items-center justify-center">
          <Skeletons type="circle" />
        </span>
        <span className="w-40">
          <Skeletons height="h-4" />
          <Skeletons height="h-4" />
        </span>
      </div>
    </div>
  );
};

const VoicemailItem = ({ item, refetch = () => null, ...rest }) => {
  const [showModal, setShowModal] = useState(initialModalState);
  const { mutate: deleteGreetingMutate, isPending: deleteGreetingLoad } =
    useDeleteGreeting({ handleSuccess: handleDeleteSuccess });

  const { user } = useAuth();

  const isChecked = item?.filename === rest?.itemValue;

  function handleDeleteSuccess() {
    handleCloseModal();
    refetch();
  }

  const handleDropListClick = ({ value = '' }, element = {}) => {
    switch (value) {
      case dropdownActions.DELETE_GREETING:
        setShowModal({
          isShow: true,
          modalType: dropdownActions.DELETE_GREETING,
          elementData: element,
          type: 'center',
        });
        break;
      case dropdownActions.DOWNLOAD_GREETING:
        downloadFile(
          `${process.env.REACT_APP_BASE_API_URL}${
            user?.company_uuid || user?.uuid
          }/greeting/${element?.filename}`,
          true,
        );
        break;

      default:
        return 'Monthly';
    }
  };

  function handleCloseModal() {
    setShowModal(initialModalState);
  }

  const ModalLookup = {
    [dropdownActions.DELETE_GREETING]: (
      <DeleteGreetingModal
        showModal={showModal}
        handleClose={handleCloseModal}
        deleteFn={deleteGreetingMutate}
        isLoading={deleteGreetingLoad}
      />
    ),
  };

  const { isShow, type, modalType } = showModal;

  return (
    <>
      <div className=" mt-5 flex items-center gap-1">
        <label
          htmlFor={item?.filename}
          className={`${
            isChecked ? ' border-green bg-green-50' : ' border-grey-400'
          } w-full border px-3 flex gap-1.5 items-center rounded-md font-medium text-base text-grey-900 focus:border-green focus:ring-green-200 xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]`}
        >
          <div className="flex  justify-between w-full gap-4">
            <div className="hidden">
              <input
                className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green cursor-pointer "
                id={item?.filename}
                type="radio"
                value={item?.filename}
                onChange={(e) => rest?.onChangeItemHandler(e.target.value)}
                checked={isChecked}
              />
            </div>
            <CustomAudioPlayer
              srcUrl={`${process.env.REACT_APP_BASE_API_URL}${
                user?.company_uuid || user?.uuid
              }/greeting/${item?.filename}`}
              filename={item?.name}
              barWidth={2}
              width={250}
              loadingItem={LoadingPlayer}
              withIcon={false}
              downloadOption={false}
            />
          </div>
          <div>
            <Dropdown
              menuWidth="w-max"
              closeOnClickOutside={true}
              dropList={{
                component: ({ item: dropDownItem }) => {
                  const Icon = dropDownItem?.icon ?? null;
                  const del = item?.type === 'del' ? 'text-danger' : '';

                  return (
                    <span
                      className={`w-full h-full flex  justify-start gap-2 items-center py-2 px-3 ${del}`}
                      onClick={() => handleDropListClick(dropDownItem, item)}
                    >
                      {Icon}
                      {dropDownItem?.title}
                    </span>
                  );
                },
                data: [
                  {
                    id: 1,
                    title: (
                      <div className="text-sm font-medium text-grey-900">
                        Download voicemail
                      </div>
                    ),
                    value: dropdownActions.DOWNLOAD_GREETING,
                    icon: <Download className="text-grey-700 w-4 h-4" />,
                  },
                  {
                    id: 2,
                    title: (
                      <div className="text-sm font-medium text-danger ">
                        Delete voicemail
                      </div>
                    ),
                    value: dropdownActions.DELETE_GREETING,
                    icon: <Trash className="text-danger w-4 h-4" />,
                    type: 'del',
                  },
                ],
              }}
              showcaret={false}
            >
              <div>
                <MenuIcon className="text-grey-700 cursor-pointer" />
              </div>
            </Dropdown>
          </div>
        </label>
      </div>
      {isShow && type === 'center' ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </>
  );
};

const BusinessHourForm = ({ control, errors, watch, setValue }) => {
  const [showModal, setShowModal] = useState(initialModalState);
  const queryClient = useQueryClient();

  const hoursLookup = {
    everyday: (
      <EveryDayHoursForm control={control} watch={watch} errors={errors} />
    ),
    weekday: (
      <WeekdaysHourForm control={control} watch={watch} errors={errors} />
    ),
    custom: <CustomHourForm control={control} watch={watch} errors={errors} />,
  };

  const handleVoicemailAudio = () => {
    setShowModal({ isShow: true, type: 'center', modalType: 'ADD_GREETING' });
  };
  const handleCloseModal = () => {
    setShowModal(initialModalState);
  };

  const { isShow, modalType, type } = showModal;

  const handleAddGreetingSuccess = (data) => {
    handleCloseModal(data);
    queryClient.invalidateQueries(['greetingList']);
  };
  const ModalLookup = {
    ADD_GREETING: (
      <AddVoicemailModal
        showModal={showModal}
        handleClose={handleCloseModal}
        handleSuccess={handleAddGreetingSuccess}
      />
    ),
  };
  return (
    <>
      <div>
        <div className="flex flex-col gap-1 mt-7">
          <div className="text-grey-900 xxl:text-base xl:text-sm	 lg:text-sm md:text-xs	 font-medium">
            Time zone
          </div>
          <CountrySelect
            title="West Africa Standard Time"
            label=""
            background="bg-grey-100 h-12"
          />
          {/* <Controller
            name="business_hour.timezone"
            control={control}
            render={({ field }) => (
              <CustomSelectServer
                {...field}
                height="xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]"
                onWhitebg={true}
                placeholder="Select timezone"
                error={errors?.business_hour?.timezone?.message}
                ItemComponent={TimezoneItem}
                labelKey={'first_name'}
                valueKey={'uuid'}
                isMulti={true}
                actionFn={() => []}
                queryKey={'timezone'}
              />
            )}
          /> */}
        </div>
      </div>
      <div className="flex flex-col gap-1 mt-6">
        <div className="text-grey-900 text-base font-medium">Schedule</div>
        <div className="text-grey-700 text-sm font-medium">
          Set your schedule. Calls within business hours ring through to the
          users assigned to this phone number, and calls outside business hours
          are sent to voicemail.
        </div>
      </div>
      <div className="mt-9">
        <Controller
          name="business_hour.days"
          control={control}
          render={({ field }) => (
            <CustomSelect
              {...field}
              height="xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]"
              placeholder=""
              onWhitebg={true}
              label=""
              error={errors?.business_hour?.days?.message}
              ItemComponent={DayItemComponent}
              valueKey={'value'}
              labelKey={'label'}
              isMulti={false}
              optionsList={daysArray}
            />
          )}
        />
      </div>
      <div className="mt-6">{hoursLookup[watch('business_hour.days')]}</div>
      <div className="flex justify-between mt-9">
        <div className="flex flex-col gap-1">
          <div className="text-grey-900 text-base font-semibold">
            After hour voicemail audio
          </div>
          <div className="text-grey-700 text-sm font-medium">
            Enable to set a custom schedule for your phone number
          </div>
        </div>
        <div
          className="text-green cursor-pointer text-base font-semibold"
          onClick={() => handleVoicemailAudio()}
        >
          Add
          {/* {watch('business_hour.after_hours') ? 'Change' : 'Add'} */}
        </div>
      </div>
      <ListManager
        actionFn={greetingList}
        queryKey="greetingList"
        listItem={VoicemailItem}
        loadingComponent={LoadingItem}
        onChangeItemHandler={(val) =>
          setValue('business_hour.after_hours', val, {
            shouldValidate: true,
          })
        }
        itemValue={watch('business_hour.after_hours')}
        extraParams={{
          action_type: 'V',
        }}
      />

      <div>
        {isShow && type === 'center' ? (
          <Modal
            handleClose={() => handleCloseModal()}
            headerComponent={null}
            footerComponent={null}
            shouldCloseOnClickOutside={false}
          >
            {ModalLookup[modalType]}
          </Modal>
        ) : null}
      </div>
    </>
  );
};

export default BusinessHourForm;
