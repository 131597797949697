import { getRequiredText } from 'shared/resources';
import * as yup from 'yup';

export const modalTypes = {
  PHONE_VERIFICATION: 'PHONE_VERIFICATION',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  CHANGE_PLAN: 'CHANGE_PLAN',
  VIEW_PLAN: 'VIEW_PLAN',
};

export const profileInetialValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '+234',
};
export const validationSchema = yup.object().shape({
  first_name: yup.string().required(getRequiredText('First name')),
  phone: yup
    .string()
    .min(6, getRequiredText('Phone'))
    .required(getRequiredText('Phone')),
});

export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
  type: '',
};

export const dropdownActions = {
  CHANGE_PLAN: 'change_plan',
  VIEW_PLAN: 'view_plan',
  BUY_PLAN: 'buy_plan',
  PURCHASE_CONFIRMATION: 'PURCHASE_CONFIRMATION',
};
