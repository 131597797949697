import React, { useEffect } from 'react';
import { Header } from 'components';
import { useSip, useUserDetails } from 'hooks';
import { Outlet, useLocation } from 'react-router-dom';
import { SipSocketContext } from 'hooks/useSip/SipSocketContext';

export default function AuthLayout() {
  const SIP = useSip();
  const { pathname } = useLocation();
  useUserDetails(SIP);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((tracks) => {
        tracks.getTracks().forEach((track) => track.stop());
      })
      .catch((err) => {
        console.log('Microphone access denied', err);
      });
  }, []);

  return (

    <SipSocketContext.Provider value={{ socket: SIP }}>
      {pathname?.includes('verify-email') ? null : <Header />}
      <Outlet />
    </SipSocketContext.Provider>

  );
}
