import { Arrow, Call3D, X } from 'assets/images';
import { Button } from 'components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function WelcomeFonuModal({
  handleCloseWelcomeModal = () => null,
}) {
  const navigate = useNavigate();
  return (
    <div className='w-full max-w-96'>
      <div className="flex justify-end items-center p-4">
        <button type="button" onClick={() => handleCloseWelcomeModal()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="text-center px-7 pb-5 ">
        <div className="text-center ">
          <img alt="call-3d-icon" src={Call3D} className="m-auto w-16" />
        </div>
        <div className="text-4xl text-black font-semibold	 mt-4 mb-1">
          Phone numbers
        </div>
        <div className="text-grey-700 text-base	mt-4">
          Would you like to get a Fonu virtual number, or want to port your
          existing number to Fonu?
        </div>
        <div className="mt-8 xs:hidden sm:block ">
          <Button
            className="mt-10 "
            type="button"
            onClick={() =>
              navigate('/phone-numbers/active-numbers?action=purchase_number')
            }
          >
            <div className="flex justify-between w-full px-5">
              Get a Fonu number
              <Arrow className="text-green-300" />
            </div>
          </Button>
        </div>
        <div className="mt-4  xs:hidden sm:block">
          <Button
            background="transparent"
            border="border-grey-400"
            type="button"
            onClick={() => navigate('/phone-numbers/port-number/form')}
          >
            <div className="flex justify-between w-full px-5 text-grey-900 ">
              Port exiting number
              <Arrow className="text-grey-900" />
            </div>
          </Button>
        </div>
        <div
          className="text-base text-green-400 font-medium	 mt-12 cursor-pointer"
          onClick={() => handleCloseWelcomeModal()}
        >
          Skip for now
        </div>
      </div>
    </div>
  );
}
