import {
  ArrowsLeftRight,
  X,
  MenuIcon,
  NoRecord,
  RemoveForwarding,
  Setting,
  ShoppingCart,
  Trash,
  User,
  Check,
} from 'assets/images';
import {
  AsideModal,
  Avatar,
  Button,
  Dropdown,
  Modal,
  Spinner,
  TableManager,
  Tooltip,
} from 'components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { dropdownActions, initialModalState } from './constants';
import {
  ADMIN,
  NG_CURRENCY_SYMBOL,
  OWNER,
  formatDateTime,
  formatPhoneNumber,
} from 'shared/resources';
import { useAuth } from 'hooks/useAuth';
import PurchaseNumberModal from './PurchaseNumberModal';
import { getDIDListing } from 'api';
import RemoveForwardingModal from './RemoveForwardingModal';
import DeleteNumberModal from './DeleteNumberModal';
import SettingsModal from './SettingsModal';
import AssignToModal from './AssignToModal';
import SetForwardingModal from './SetForwardingModal';
import SetDefaultDidModal from './SetDefaultDidModal';
import {
  useGetCart,
  useReleaseDID,
  useRemoveForwarding,
  useSetDefaultDid,
} from 'hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AvatarGroup from 'components/AvatarGroup';
import useBulkDelete from 'hooks/useBulkDelete';
import GetForwarding from 'components/GetForwarding';
import { toastEmitter } from 'components/Toast';

const BulkDeleteComponent = ({ data = [], callback = () => null }) => {
  const { mutate, isPending, status } = useBulkDelete();

  useEffect(() => {
    if (status && status === 'success') {
      callback();
    }
  }, [status]);
  return (
    <div className="w-64">
      <Button
        height="h-10"
        type="button"
        background="danger"
        onClick={() =>
          mutate({
            type: 'did',
            uuids: data,
          })
        }
      >
        {isPending ? <Spinner /> : `Delete ${data?.length} Phone number(s) -`}
      </Button>
    </div>
  );
};

const SepratorItem = ({ list = [], setShow = () => null }) => {
  const result =
    list?.map((option) => {
      return (
        <div key={option?.uuid} className="flex items-center gap-3 ">
          <Avatar name={option?.name} inGroup={true} />
          {option?.name}
        </div>
      );
    }) ?? null;

  return (
    <div>
      <div className="flex justify-end items-start ">
        <button type="button" onClick={() => setShow(false)}>
          <X className="text-grey-600 w-4 h-4" />
        </button>
      </div>

      <div className="flex flex-col gap-2 xxl:max-h-[330px] xl:max-h-[330px] lg:max-h-[330px] md:max-h-[234px]">
        {result}
      </div>
    </div>
  );
};

const NoRecordItem = () => {
  return (
    <div className="w-full grid place-content-center text-grey-600 gap-3 ">
      <div className="flex items-center justify-center flex-col h-[calc(100vh_-_309px)]">
        <NoRecord />
        <span className="text-grey-600">
          You have no active phone number, purchase a Fonu number or port an
          existing number
        </span>
      </div>
    </div>
  );
};

const filterInitialValues = {
  filter: [
    {
      phone: '',
      a_filter_type: '',
      placeholder: 'Enter phone',
      type: 'text',
      shouldFilterOptionDisabled: false,
      label: 'Phone',
      filterName: 'phone',
      position: 1,
    },
  ],
};

function PhoneNumbers() {
  const navigate = useNavigate();
  const tableRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const actionModal = searchParams?.get('action');
  const [showModal, setShowModal] = useState(initialModalState);
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState([]);
  const [cartListing, setCartListing] = useState([]);
  const { user } = useAuth();
  const { data: cartListingData } = useGetCart();

  useEffect(() => {
    if (actionModal) {
      setShowModal({ isShow: true, modalType: actionModal, type: 'center' });
    }
  }, [actionModal]);

  useEffect(() => {
    if (
      cartListingData?.data?.data &&
      cartListingData?.data?.data?.length > 0
    ) {
      const cartListing = cartListingData?.data?.data ?? [];
      setCartListing(cartListing);
    } else {
      setCartListing([]);
    }
  }, [cartListingData?.data?.data]);

  const handleSuccess = () => {
    handleCloseModal();
  };
  const { mutate: setDefaultDidMutate, isPending: setDefaultDidLoad } =
    useSetDefaultDid({ handleSuccess });

  const { mutate: removeForwardingMutate, isPending: removeForwardingLoad } =
    useRemoveForwarding({ handleSuccess });
  const { mutate: releaseDIDMutate, isPending: releaseDIDLoad } = useReleaseDID(
    { onSuccessFn: handleSuccess },
  );

  const checkboxSelectableFn = () => {
    return true;
  };

  const handleDropListClick = ({ value = '' }, element = {}) => {
    switch (value) {
      case dropdownActions.ASSIGN_TO:
      case dropdownActions.REMOVE_FORWARDING:
      case dropdownActions.DELETE_NUMBER:
      case dropdownActions.SET_FORWARDING:
      case dropdownActions.SET_DEFAULT_DID:
      case dropdownActions.UPDATE_FORWARDING:
        setShowModal({
          isShow: true,
          modalType: value,
          elementData: element,
          type: 'center',
        });
        break;

      case dropdownActions.SETTINGS:
        setShowModal({
          isShow: true,
          modalType: value,
          elementData: element,
          type: 'side',
        });
        break;

      default:
        break;
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'check',
        header: (props) => {
          return (
            <input
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.table?.getIsAllRowsSelected()}
              onChange={props?.table?.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: (props) => {
          return (
            <input
              key={props?.row?.id}
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.row?.getIsSelected()}
              onChange={props?.row?.getToggleSelectedHandler()}
            />
          );
        },
        meta: {
          colType: 'action',
          width: '5%',
          hasAccess: [OWNER, ADMIN].includes(user?.role_id),
        },
      },
      {
        accessorKey: 'buy_date',
        header: () => 'Date',
        cell: (props) => {
          const [date] = formatDateTime(props?.getValue(), false);
          return date;
        },
        meta: {
          isSortable: true,
        },
      },
      {
        accessorKey: 'did_number',
        header: () => 'Phone number',
        cell: (props) => {
          const element = props?.row?.original ?? {};
          const didName = element?.settings?.incoming_call?.name;
          const didIcon = element?.settings?.incoming_call?.icon
            ? `${process.env.REACT_APP_BASE_API_URL}${
                user?.company_uuid || user?.uuid
              }/icon/${element?.settings?.incoming_call?.icon}`
            : null;

          const isDefaultDID = element?.is_active_caller_id === 1;
          return (
            <div className="flex gap-1 items-center">
              <Avatar
                mediaUrl={didIcon}
                name={didName ?? 'Unknown'}
                type="icon"
              />
              {didName ? (
                <div>
                  <div className="flex items-center content-center gap-1">
                    {didName}
                    {isDefaultDID ? (
                      <Tooltip title="Default">
                        <span className="text-xs text-green flex items-center gap-1">
                          <Check className="h-3 w-3" />
                        </span>
                      </Tooltip>
                    ) : null}
                  </div>
                  <div className="text-grey-600 text-xs font-medium ">
                    {formatPhoneNumber(props?.getValue())}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex items-center content-center gap-1">
                    {formatPhoneNumber(props?.getValue())}{' '}
                    {isDefaultDID ? (
                      <Tooltip title="Default">
                        <span className="text-xs text-green flex items-center gap-1">
                          <Check className="h-3 w-3" />
                        </span>
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: 'settings.privacy_control.business_hour_type',
        header: () => 'Business hour',
        cell: (props) => {
          const element = props?.getValue();
          return (
            <div>
              {element === 'everyday'
                ? 'Everyday'
                : element === 'weekday'
                ? 'Weekday'
                : element === 'custom'
                ? 'Custom'
                : '---'}
            </div>
          );
        },
      },
      {
        accessorKey: 'forward_call_actions',
        id: 'forward_call_actions_for_forward_to',
        header: () => 'Forward to',
        cell: (props) => {
          const element = props?.getValue();
          const forward_type = element?.during_business_hour?.type ?? '---';
          const forward_value = element?.during_business_hour?.value ?? '';
          return (
            <GetForwarding
              value={forward_value}
              key={props?.row?.id}
              type={forward_type}
            />
          );
        },
      },
      {
        accessorKey: 'assigned_members',
        header: () => 'Assigned to',
        cell: (props) => {
          if (
            (props?.getValue() && props?.getValue()?.length === 0) ||
            !props?.getValue()
          ) {
            return '---';
          }
          return (
            <AvatarGroup
              id={props?.row?.id}
              options={props?.getValue()}
              accessorKey="name"
              sepratorComponent={SepratorItem}
            />
          );
        },
      },
      {
        accessorKey: 'is_active',
        header: () => 'Number status',
        cell: (props) => {
          const element = props?.getValue();
          return element === 'N' ? (
            <Tooltip title="Status is pending because your KYC is pending.">
              <div
                className="flex gap-2 items-center text-[#E29704] cursor-pointer justify-start"
                onClick={() => navigate('/KYC/customer-kyc')}
              >
                <span className="inline-flex h-3 w-3 rounded-full bg-[#E29704]"></span>
                Pending
              </div>
            </Tooltip>
          ) : (
            <div className="flex gap-2 items-center  text-green justify-start">
              <span className="inline-flex h-3 w-3 rounded-full bg-green text-green items-center"></span>
              Active
            </div>
          );
        },
      },
      {
        accessorKey: 'buy_duration',
        header: () => 'Plan',
        cell: (props) => {
          return (
            <div className="flex items-center gap-1 capitalize">
              {['MONTHLY', 'ANNUALLY'].includes(props?.getValue())
                ? props?.getValue()?.toLowerCase()
                : props?.getValue()}
            </div>
          );
        },
      },

      {
        accessorKey: 'monthly_cost',
        header: () => 'Cost',
        cell: (props) => `${NG_CURRENCY_SYMBOL}${props?.getValue()}`,
        meta: {
          isSortable: true,
        },
      },
      {
        accessorKey: 'action',
        header: () => '',
        cell: (props) => {
          const element = props?.row?.original || {};
          const onlyShowDeleteIcon = element?.status !== 'Y';
          if (element?.is_active === 'N') return;
          const initialOptions = onlyShowDeleteIcon
            ? [
                {
                  id: 6,
                  title: 'Delete number',
                  value: dropdownActions.DELETE_NUMBER,
                  icon: <Trash className=" text-danger " />,
                  type: 'del',
                },
              ]
            : [
                {
                  id: 1,
                  title: 'Assign to',
                  value: dropdownActions.ASSIGN_TO,
                  icon: <User className="text-grey-700" />,
                },

                {
                  id: 4,
                  title: 'Set default DID',
                  value: dropdownActions.SET_DEFAULT_DID,
                  icon: <RemoveForwarding className="text-grey-700" />,
                },
                {
                  id: 6,
                  title: 'Delete number',
                  value: dropdownActions.DELETE_NUMBER,
                  icon: <Trash className=" text-danger " />,
                  type: 'del',
                },
                {
                  id: 5,
                  title: 'Settings',
                  value: dropdownActions.SETTINGS,
                  icon: <Setting className="text-grey-700" />,
                },
              ];
          const isForwardingAvailable = Boolean(element?.forward_call_actions);
          if (isForwardingAvailable && !onlyShowDeleteIcon) {
            initialOptions.push({
              id: 3,
              title: 'Remove forwarding',
              value: dropdownActions.REMOVE_FORWARDING,
              icon: <RemoveForwarding className="text-grey-700 w-4 h-4" />,
            });
            initialOptions.push({
              id: 2,
              title: 'Update forwarding',
              value: dropdownActions.UPDATE_FORWARDING,
              icon: <ArrowsLeftRight className="text-grey-700 w-4 h-4" />,
            });
          } else {
            if (!onlyShowDeleteIcon) {
              initialOptions.push({
                id: 2,
                title: 'Set forwarding',
                value: dropdownActions.SET_FORWARDING,
                icon: <ArrowsLeftRight className="text-grey-700 w-4 h-4" />,
              });
            }
          }
          return (
            <div className="flex items-center gap-2">
              <Dropdown
                menuWidth="w-max"
                maxHeight={false}
                closeOnClickOutside={true}
                dropList={{
                  component: ({ item }) => {
                    const Icon = item?.icon ?? null;
                    const del = item?.type === 'del' ? 'text-danger' : '';
                    return (
                      <span
                        className={`w-full h-full flex  justify-start gap-2 items-center  py-2 px-3 ${del}`}
                        onClick={() => handleDropListClick(item, element)}
                      >
                        {Icon}
                        {item?.title}
                      </span>
                    );
                  },
                  data: initialOptions,
                }}
                showcaret={false}
              >
                <div className="cursor-pointer w-1">
                  <MenuIcon />
                </div>
              </Dropdown>
            </div>
          );
        },
        meta: {
          colType: 'action',
          width: '5%',
        },
      },
    ],
    [],
  );

  function handleCloseModal() {
    setSearchParams('');
    setShowModal(initialModalState);
  }

  function shouldPurchaseNumber() {
    const requireValue =
      user?.get_company_details?.features?.find(
        (feat) => feat.key === 'phone_numbers_per_user',
      )?.value ?? '0';

    if (tableRef?.current?.getTableRecordsLength() >= Number(requireValue)) {
      return toastEmitter('error', 'You have reached your plan limit.');
    } else {
      setShowModal({
        isShow: true,
        modalType: dropdownActions.PURCHASE_NUMBER,
        type: 'center',
      });
    }
  }

  const ModalLookup = {
    [dropdownActions.PURCHASE_NUMBER]: (
      <PurchaseNumberModal
        showModal={showModal}
        handleClose={handleCloseModal}
      />
    ),
    [dropdownActions.ASSIGN_TO]: (
      <AssignToModal showModal={showModal} handleClose={handleCloseModal} />
    ),
    [dropdownActions.REMOVE_FORWARDING]: (
      <RemoveForwardingModal
        showModal={showModal}
        handleClose={handleCloseModal}
        deleteFn={removeForwardingMutate}
        isLoading={removeForwardingLoad}
      />
    ),
    [dropdownActions.SET_DEFAULT_DID]: (
      <SetDefaultDidModal
        showModal={showModal}
        handleClose={handleCloseModal}
        actionFn={setDefaultDidMutate}
        isLoading={setDefaultDidLoad}
      />
    ),
    [dropdownActions.DELETE_NUMBER]: (
      <DeleteNumberModal
        showModal={showModal}
        handleClose={handleCloseModal}
        deleteFn={releaseDIDMutate}
        isLoading={releaseDIDLoad}
      />
    ),
    [dropdownActions.SETTINGS]: (
      <SettingsModal showModal={showModal} handleClose={handleCloseModal} />
    ),
    [dropdownActions.SET_FORWARDING]: (
      <SetForwardingModal
        showModal={showModal}
        handleClose={handleCloseModal}
      />
    ),
    [dropdownActions.UPDATE_FORWARDING]: (
      <SetForwardingModal
        showModal={showModal}
        handleClose={handleCloseModal}
      />
    ),
  };

  const tableActions = [
    {
      id: 1,
      label: 'Get a Fonu number +',
      access: [OWNER]?.includes(user?.role_id) || true,
      component: (
        <div className=" w-64">
          <Button
            type="button"
            height="h-10"
            onClick={() => shouldPurchaseNumber()}
          >
            Get a Fonu number +
          </Button>
        </div>
      ),
    },
  ];

  const bulkActions = [
    {
      id: 1,
      component: BulkDeleteComponent,
    },
  ];

  const { isShow, modalType, type } = showModal;

  return (
    <>
      <div className="flex items-center justify-between mb-5">
        <div className="text-sm text-grey-700">
          Manage phone numbers in your workspace
        </div>
        {cartListing.length ? (
          <Button
            width="w-[105px]"
            height="h-[38px]"
            rounded={true}
            extraClasses=" px-5"
            background="grey-200 "
            border="none"
            onClick={() => navigate('/phone-numbers/cart')}
          >
            <div className="text-black flex items-center gap-2 mr-2 font-semibold ">
              <ShoppingCart /> Cart:
            </div>
            <span className="text-grey-900 font-semibold">
              {cartListing?.length ?? 0}
            </span>
          </Button>
        ) : null}
      </div>

      <TableManager
        ref={tableRef}
        fetcherFn={getDIDListing}
        fetcherKey={'getDIDListing'}
        searchPlaceholder={'Search by number or name'}
        searchLabel=""
        isSearchable={true}
        shouldFilter={true}
        columns={columns}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        tableActions={tableActions}
        noRecordItem={NoRecordItem}
        filterInitialValues={filterInitialValues}
        setFilters={setFilters}
        filters={filters}
        bulkActions={bulkActions}
        checkboxSelectableFn={checkboxSelectableFn}
      />
      {isShow && type === 'center' ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
      {isShow && type === 'side' ? (
        <AsideModal
          handleClose={handleCloseModal}
          footerComponent={null}
          headerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </AsideModal>
      ) : null}
    </>
  );
}

export default PhoneNumbers;
