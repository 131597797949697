import React, { useState } from 'react';
import NumberDetail from './NumberDetail';
import NumberListing from './NumberListing';

const PurchaseNumberModal = ({ handleClose = () => null }) => {
  const [selectedNumber, setSelectedNumber] = useState(null);

  return (
    <div className="min-w-[500px]">
      {selectedNumber ? (
        <NumberDetail
          handleClose={handleClose}
          selectedNumber={selectedNumber}
          setSelectedNumber={setSelectedNumber}
        />
      ) : (
        <NumberListing
          setSelectedNumber={setSelectedNumber}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default PurchaseNumberModal;
