import { getDIDListing } from 'api';
import { Check, X } from 'assets/images';
import { Avatar, Button, Spinner, TableManager, Tooltip } from 'components';
import { useAuth } from 'hooks/useAuth';
import useDashboardStats from 'hooks/useDashboardStats';
import useRequestPlan from 'hooks/useRequestPlan';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ADMIN,
  NG_CURRENCY_SYMBOL,
  OWNER,
  formatDateTime,
  formatPhoneNumber,
} from 'shared/resources';

const BulkDeleteComponent = ({ data = [], setHasDIDToDelete }) => {
  useEffect(() => {
    setHasDIDToDelete(data);

    return () => {
      setHasDIDToDelete([]);
    };
  }, [data?.length]);

  return null;
};

export const ViewPlanModal = ({
  handleClose = () => null,
  showModal = {},
  allPlans = [],
}) => {
  const { user } = useAuth();

  const { data, isLoading } = useDashboardStats({
    statsType: 'weekly',
  });

  const purchasedVirtualNumbers = data?.data?.data?.virtual_number ?? 0;

  const { mutate, isPending } = useRequestPlan({ handleClose });

  const [hasDIDToDelete, setHasDIDToDelete] = useState([]);

  const currentPlan =
    allPlans?.find(
      (plan) => plan?.uuid === showModal?.elementData?.currentPlan,
    ) ?? {};

  const requestedPlan =
    allPlans?.find((plan) => plan?.uuid === showModal?.elementData?.newPlan) ??
    {};

  const limitedVirtualNumbers =
    requestedPlan?.features?.find(
      (val) => val?.key === 'phone_numbers_per_user',
    )?.value ?? 0;

  const atLeastNumberToDelete = Math.abs(
    purchasedVirtualNumbers - parseInt(limitedVirtualNumbers) || 0,
  );

  const checkboxSelectableFn = () => {
    return true;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'check',
        header: (props) => {
          return (
            <input
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.table?.getIsAllRowsSelected()}
              onChange={props?.table?.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: (props) => {
          return (
            <input
              key={props?.row?.id}
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.row?.getIsSelected()}
              onChange={props?.row?.getToggleSelectedHandler()}
            />
          );
        },
        meta: {
          colType: 'action',
          width: '5%',
          hasAccess: [OWNER, ADMIN].includes(user?.role_id),
        },
      },
      {
        accessorKey: 'did_number',
        header: () => 'Phone number',
        cell: (props) => {
          const element = props?.row?.original ?? {};
          const didName = element?.settings?.incoming_call?.name;
          const didIcon = element?.settings?.incoming_call?.icon
            ? `${process.env.REACT_APP_BASE_API_URL}${
                user?.company_uuid || user?.uuid
              }/icon/${element?.settings?.incoming_call?.icon}`
            : null;

          const isDefaultDID = element?.is_active_caller_id === 1;
          return (
            <div className="flex gap-1 items-center">
              <Avatar
                mediaUrl={didIcon}
                name={didName ?? 'Unknown'}
                type="icon"
              />
              {didName ? (
                <div>
                  <div className="flex items-center content-center gap-1">
                    {didName}
                    {isDefaultDID ? (
                      <Tooltip title="Default">
                        <span className="text-xs text-green flex items-center gap-1">
                          <Check className="h-3 w-3" />
                        </span>
                      </Tooltip>
                    ) : null}
                  </div>
                  <div className="text-grey-600 text-xs font-medium ">
                    {formatPhoneNumber(props?.getValue())}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex items-center content-center gap-1">
                    {formatPhoneNumber(props?.getValue())}{' '}
                    {isDefaultDID ? (
                      <Tooltip title="Default">
                        <span className="text-xs text-green flex items-center gap-1">
                          <Check className="h-3 w-3" />
                        </span>
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: 'buy_date',
        header: () => 'Start date',
        cell: (props) => {
          const [date] = formatDateTime(props?.getValue(), false);
          return date;
        },
      },
      {
        accessorKey: 'renewal_date',
        header: () => 'Renewal date',
        cell: (props) => {
          const [date] = formatDateTime(props?.getValue(), false);
          return date;
        },
      },

      {
        accessorKey: 'buy_duration',
        header: () => 'Plan',
        cell: (props) => {
          return (
            <div className="flex items-center gap-1 capitalize">
              {['MONTHLY', 'ANNUALLY'].includes(props?.getValue())
                ? props?.getValue()?.toLowerCase()
                : props?.getValue()}
            </div>
          );
        },
      },

      {
        accessorKey: 'setup_cost',
        header: () => 'Cost',
        cell: (props) => `${NG_CURRENCY_SYMBOL}${props?.getValue()}`,
      },
    ],
    [],
  );

  const bulkActions = [
    {
      id: 1,
      component: (props) => (
        <BulkDeleteComponent {...props} setHasDIDToDelete={setHasDIDToDelete} />
      ),
    },
  ];

  return (
    <>
      <div className="flex justify-between items-start py-4 px-6  md:w-[900px] lg:w-[1252px]">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            Requested plan details
          </div>
          <div className="text-sm  text-grey-700">
            Select the numbers to delete
          </div>
        </div>
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="px-6 pb-6 flex gap-3 flex-col">
        {isLoading ? (
          <Spinner />
        ) : (
          <TableManager
            fetcherFn={getDIDListing}
            fetcherKey={'getDIDListing'}
            columns={columns}
            shouldRefresh={false}
            checkboxSelectableFn={checkboxSelectableFn}
            bulkActions={bulkActions}
          />
        )}

        <div className="grid grid-rows-1 grid-flow-col gap-4">
          <div className="rounded-md border border-grey-400  p-6">
            <div className="flex items-center justify-between py-2">
              <div className="text-xl font-semibold text-black">
                Current plan
              </div>
              <div className="text-xl font-semibold text-green">
                {currentPlan?.plan_name ?? ''}
              </div>
            </div>
          </div>
          <div className="rounded-md border border-grey-400  p-6">
            <div className="flex items-center justify-between py-2">
              <div className="text-xl font-semibold text-black">
                Requested plan
              </div>
              <div className="text-xl font-semibold text-green">
                {requestedPlan?.plan_name ?? ''}
              </div>
            </div>
            {/* <div className="flex justify-between py-2 mt-2">
              <div className="text-sm flex gap-2">
                <div className="text-green">
                  <Check className="w-6" />
                </div>{' '}
                Phone numbers per user
              </div>
              <div className="text-sm">1</div>
            </div>
            <div className="flex justify-between py-2">
              <div className="text-sm flex  gap-2">
                <div className="text-green">
                  <Check className="w-6" />
                </div>{' '}
                Discount on OffNet calls
              </div>
              <div className="text-sm">10%</div>
            </div> */}
          </div>
        </div>

        <div className="flex items-center justify-end gap-6 mt-[73px]">
          <Button
            type="submit"
            width="w-[191px]"
            disabled={
              isPending ||
              isLoading ||
              hasDIDToDelete?.length < atLeastNumberToDelete
            }
            onClick={() =>
              mutate({
                old_plan_uuid: showModal?.elementData?.currentPlan,
                plan_uuid: showModal?.elementData?.newPlan,
                type: 'Downgrade Plan',
                removed_did_uuids: hasDIDToDelete,
              })
            }
          >
            {isPending ? <Spinner /> : 'Request'}
          </Button>
        </div>
      </div>
    </>
  );
};
