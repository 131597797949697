import React, { useState } from 'react';
import { getDIDMeta } from 'api';
import { X } from 'assets/images';
import { CountrySelect, Input, Tabs } from 'components';
import ListManager from 'components/ListManager';
import { LoadingItem } from 'pages/Dashboard';
import { NG_CURRENCY_SYMBOL } from 'shared/resources';
import { useDebounce } from 'hooks';

const paramLookup = {
  did_number: 'did_number',
  by_areacode: 'area_code',
  toll_free: 'did_type',
};

const NumberItem = ({ item, ...rest }) => {
  return (
    <div
      key={item?.uuid}
      value={item?.value}
      className="flex gap-3 items-center justify-between pb-5 pt-5 w-full cursor-pointer border-b-[1px]  border-grey-300 last:border-none"
      onClick={() => rest?.onChangeItemHandler(item)}
    >
      <div className="flex items-center">
        <div className="  font-semibold flex items-center gap-1.5">
          {item?.did_number}
          <div className="text-grey-600 font-medium text-sm">
            {item?.did_city ? `(${item?.did_city})` : null}
          </div>
        </div>
      </div>
      <div className="flex items-center font-medium gap-1">
        {item?.did_type === 'T' ? (
          <>
            <div className="text-grey-600 font-medium text-sm">Toll-Free</div>
          </>
        ) : item?.monthly_cost ? (
          <>
            {NG_CURRENCY_SYMBOL} {item?.monthly_cost} /
            <div className="text-grey-600 font-medium text-sm">Month</div>
          </>
        ) : null}
      </div>
    </div>
  );
};

const NumberListing = ({ handleClose = () => null, setSelectedNumber }) => {
  const [numberType, setNumberType] = useState('did_number');
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const isTollFree = numberType === 'toll_free';

  const handlePlaceholder = (type) => {
    switch (type) {
      case 'did_number':
        return 'Enter a city name or an area code (e.g. 415)';

      case 'toll_free':
        return 'Filter by digits or phrases';
      default:
        return 'sadsa';
    }
  };
  return (
    <>
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            Purchase a number
          </div>
          <div className="text-sm  text-grey-700">Select a number</div>
        </div>
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="pb-5 ">
        <div className="flex flex-col gap-4 mb-5 px-6">
          <div>
            <CountrySelect background="bg-grey-100" withLabel={false} />
          </div>
        </div>

        <div className="mb-5 px-6">
          <Tabs
            height="h-10"
            onWhitebg={true}
            tabList={[
              {
                id: 1,
                label: 'Virtual Number',
                value: 'did_number',
                onclick: () => setNumberType('did_number'),
              },
              {
                id: 2,
                label: 'Toll-Free',
                value: 'toll_free',
                onclick: () => setNumberType('toll_free'),
              },
            ]}
            currentTab={numberType}
          />
        </div>
        <div className="px-6">
          {!isTollFree ? (
            <Input
              onWhitebg={true}
              withSearchIcon={true}
              height="h-[40px] "
              placeholder={handlePlaceholder(numberType)}
              extraClasses="pl-10 mb-0 placeholder:text-grey-600 placeholder:font-normal placeholder:text-sm  	"
              onChange={(e) => setSearchValue(e.target.value)}
            />
          ) : null}
        </div>
        <div className="px-6 overflow-y-auto max-h-96">
          <ListManager
            extraParams={{
              country: 'NG',
              key: paramLookup[numberType],
              value: isTollFree ? 'T' : debouncedSearchValue,
            }}
            actionFn={getDIDMeta}
            queryKey="getDIDMeta"
            listItem={NumberItem}
            loadingComponent={LoadingItem}
            onChangeItemHandler={setSelectedNumber}
            withPageParam={false}
          />
        </div>
      </div>
    </>
  );
};

export default NumberListing;
