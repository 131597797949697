export const routes = {
  LOGIN: {
    METHOD: 'POST',
    URL: 'auth/login',
  },
  SEND_OTP: {
    METHOD: 'POST',
    URL: 'auth/otp',
  },
  VALIDATE: {
    METHOD: 'POST',
    URL: 'account/validate',
  },
  USER_DETAILS: {
    METHOD: 'GET',
    URL: 'auth/get-user-details',
  },
  USER_DETAILS_BY_PARAMS: {
    METHOD: 'POST',
    URL: 'auth/get-user-details',
  },
  SIGNUP: {
    METHOD: 'POST',
    URL: 'auth/signup-final-step',
  },
  FORGOT_PASSWORD: {
    METHOD: 'POST',
    URL: 'auth/update-password',
  },
  USER_CREDENTIALS: {
    METHOD: 'GET',
    URL: 'auth/user-credential',
  },
  DID_LISTING: {
    METHOD: 'POST',
    URL: 'did/listing',
  },
  MEMBER_LISTING: {
    METHOD: 'POST',
    URL: 'member/listing',
  },
  MEMBER_SIGNUP: {
    METHOD: 'POST',
    URL: 'member/add',
  },
  MEMBER_UPDATE: {
    METHOD: 'POST',
    URL: 'member/update',
  },
  MEMBER_DELETE: {
    METHOD: 'DELETE',
    URL: 'member/delete',
  },
  SET_DEFAULT_DID: {
    METHOD: 'POST',
    URL: 'member/set-default-did',
  },
  ASSIGN_DID: {
    METHOD: 'POST',
    URL: 'member/assign-unassign-did',
  },
  ASSIGN_TEAM: {
    METHOD: 'POST',
    URL: 'member/assign-unassign-team',
  },
  RESEND_VERIFICATION_LINK: {
    METHOD: 'POST',
    URL: 'member/resend-verification-link',
  },
  VERIFY_PHONE: {
    METHOD: 'POST',
    URL: 'member/verify-phone',
  },
  VERIFY_EMAIL: {
    METHOD: 'POST',
    URL: 'member/verify-email',
  },
  TEAM_LISTING: {
    METHOD: 'POST',
    URL: 'team/listing',
  },
  CREATE_TEAM: {
    METHOD: 'POST',
    URL: 'team/add',
  },
  UPDATE_TEAM: {
    METHOD: 'POST',
    URL: 'team/update',
  },
  DELETE_TEAM: {
    METHOD: 'DELETE',
    URL: 'team/delete',
  },
  ASSIGN_UNASSIGN_MEMBER: {
    METHOD: 'POST',
    URL: 'team/assign-unassign-member',
  },
  ASSIGN_UNASSIGN_MEMBER_NUMBER: {
    METHOD: 'POST',
    URL: 'did/assign-unassign-member',
  },
  DID_PURCHASE_META: {
    METHOD: 'POST',
    URL: 'did/purchase-did-list',
  },
  SET_FORWARDING: {
    METHOD: 'POST',
    URL: 'did/set-forwarding',
  },
  REMOVE_FORWARDING: {
    METHOD: 'POST',
    URL: 'did/remove-forwarding',
  },
  SET_FORWARDING_SETTINGS: {
    METHOD: 'POST',
    URL: 'did/set-forwarding-settings',
  },
  BUY_DID: {
    METHOD: 'POST',
    URL: 'did/buy',
  },
  RELEASE_DID: {
    METHOD: 'DELETE',
    URL: 'did/release',
  },

  //Cart Apis
  ADD_CART_ITEM: {
    METHOD: 'POST',
    URL: 'cart/add',
  },
  UPDATE_CART_ITEM: {
    METHOD: 'POST',
    URL: 'cart/update-item',
  },
  GET_CART: {
    METHOD: 'GET',
    URL: 'cart/get',
  },
  REMOVE_CART_ITEM: {
    METHOD: 'DELETE',
    URL: 'cart/remove-item',
  },

  GREETING_LIST: {
    METHOD: 'POST',
    URL: 'greeting/list',
  },

  CREATE_GREETING: {
    METHOD: 'POST',
    URL: 'greeting/create',
  },
  UPDATE_GREETING: {
    METHOD: 'POST',
    URL: 'greeting/update',
  },
  DELETE_GREETING: {
    METHOD: 'DELETE',
    URL: 'greeting/delete',
  },
  DELETE_KYC: {
    METHOD: 'DELETE',
    URL: 'kyc/delete',
  },
  UPLOAD_MEDIA: {
    METHOD: 'POST',
    URL: `upload-url`,
  },
  CALLS_HISTORY: {
    METHOD: 'POST',
    URL: 'calls/history',
  },
  CREATE_IVR: {
    METHOD: 'POST',
    URL: 'ivr/create',
  },
  UPDATE_IVR: {
    METHOD: 'POST',
    URL: 'ivr/update',
  },
  DELETE_IVR: {
    METHOD: 'DELETE',
    URL: 'ivr/delete',
  },
  IVR_LIST: {
    METHOD: 'POST',
    URL: 'ivr/list',
  },
  BULK_DELETE: {
    METHOD: 'DELETE',
    URL: 'account/bulk-delete',
  },
  DASHBOARD_STATS: {
    METHOD: 'POST',
    URL: 'dash-stats',
  },
  UPDATE_PROFILE: {
    METHOD: 'POST',
    URL: 'auth/profile-update',
  },
  GET_VERIFICATION_STATUS: {
    METHOD: 'POST',
    URL: 'auth/get-verification-status',
  },
  MAKE_PAYMENT: {
    METHOD: 'POST',
    URL: 'did/checkout',
  },
  VERIFY_PAYMENT: {
    METHOD: 'POST',
    URL: 'did/verify-payment',
  },
  VERIFY_DEPOSIT: {
    METHOD: 'POST',
    URL: 'did/checkout-deposit',
  },
  VERIFY_FUND: {
    METHOD: 'POST',
    URL: 'did/checkout-fund',
  },
  GET_COMPANY_DETAILS: {
    METHOD: 'GET',
    URL: 'auth/get-company-details',
  },
  UPDATE_COMPANY_DETAILS: {
    METHOD: 'POST',
    URL: 'auth/update-company-details',
  },
  GET_PLANS: {
    METHOD: 'POST',
    URL: 'plans/get-plans',
  },
  CHANGE_PLAN: {
    METHOD: 'POST',
    URL: 'plans/checkout-plan',
  },
  ADD_KYC: {
    METHOD: 'POST',
    URL: 'kyc/add',
  },
  UPDATE_KYC: {
    METHOD: 'POST',
    URL: 'kyc/update',
  },
  KYC_LIST: {
    METHOD: 'POST',
    URL: 'kyc/get',
  },
  BUNDLE_LIST: {
    METHOD: 'POST',
    URL: 'bundles/list',
  },
  BUNDLE_CHECKOUT: {
    METHOD: 'POST',
    URL: 'bundles/checkout',
  },
  MY_BUNDLE_LIST: {
    METHOD: 'POST',
    URL: 'bundles/my-bundles',
  },
  GET_SAVED_CARDS: {
    METHOD: 'POST',
    URL: 'cards/my-cards',
  },
  SET_DEFAULT_CARD: {
    METHOD: 'POST',
    URL: 'cards/set-default-card',
  },
  DELETE_CARD: {
    METHOD: 'DELETE',
    URL: 'cards/delete-card',
  },
  TRANSACTION_LIST: {
    METHOD: 'POST',
    URL: 'transactions/transaction-list',
  },
  GET_WALLET_AMOUNT: {
    METHOD: 'POST',
    URL: 'wallet/get-amounts',
  },
  CHECKOUT_WALLET: {
    METHOD: 'POST',
    URL: 'wallet/checkout',
  },
  WALLET_VERIFY_PAYMENT: {
    METHOD: 'POST',
    URL: 'wallet/verify-payment',
  },
  WALLET_CHECKOUT_DEPOSIT: {
    METHOD: 'POST',
    URL: 'wallet/checkout-deposit',
  },
  WALLET_DETAILS: {
    METHOD: 'POST',
    URL: 'wallet/my-wallet-amount',
  },
  WALLET_AUTO_TOPUP: {
    METHOD: 'POST',
    URL: 'wallet/set-auto-topup',
  },
  REQUEST_PLAN: {
    METHOD: 'POST',
    URL: 'plans/request-plan',
  },
  GET_REQUESTED_PLAN: {
    METHOD: 'POST',
    URL: 'plans/requested-plan',
  },
  CANCEL_REQUEST: {
    METHOD: 'DELETE',
    URL: 'plans/cancel-request',
  },
};
