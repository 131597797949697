import { X } from 'assets/images';
import Button from 'components/Button';
import React from 'react';

const PaymentConfirmationModal = ({
  handleClose = () => null,
  continueCallBack = () => null,
  showModal
}) => {
  return (
    <>
      <div className="flex justify-end items-center p-4">
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="text-center px-16 pb-5 justify-center">
        <div className="text-4xl text-black font-semibold	 mt-4 mb-1">
          Payment Confirmation
        </div>
        <div className="text-grey-700 text-base	mt-4 w-96">
          Are you sure you want to proceed with this payment?
        </div>
        <div className="mt-10 pb-14 flex items-center gap-4">
          <Button
            type="button"
            background="transparent"
            border="border-grey-400"
            color="text-grey-900"
            onClick={() => handleClose()}
          >
            <div className="flex w-full px-5 justify-center items-center ">
              Cancel
            </div>
          </Button>
          <Button
            type="button"
            onClick={() => {
              continueCallBack(showModal);
              handleClose();
            }}
          >
            <div className="flex w-full px-5 justify-center items-center">
              Continue
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default PaymentConfirmationModal;
