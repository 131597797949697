import { Button, CountrySelect, Modal, Skeletons } from 'components';
import React, { useState, useMemo } from 'react';
import { dropdownActions, initialModalState } from './constants';
import { PurchaseBundleModal } from './PurchaseBundleModal';
import { useForm } from 'react-hook-form';
import BundleItem from './BundleItem';
import { useBundleListing } from 'hooks';
import useMyBundles from 'hooks/useMyBundles';
import { Billing, Clock } from 'assets/images';
import { formatDateTime, SecondsTohhmmss } from 'shared/resources';
import { useAuth } from 'hooks/useAuth';

const initialValues = {
  country: 'NG',
  call_bundle: '',
  sms_bundle: '',
};

function Bundle() {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(initialModalState);
  const [purchaseBundleModal, setPurchaseBundleModal] = useState(false);
  const { data, isLoading } = useMyBundles();
  const {
    data: bundleListing,
    isLoading: loadingBundleListing,
    isFetching: fetchingBundleListing,
  } = useBundleListing();

  const ModalLookup = useMemo(() => {
    return {
      [dropdownActions.PURCHASE_BUNDLE]: (
        <PurchaseBundleModal
          showModal={showModal}
          handleClose={handleModalClose}
          setShowModal={setShowModal}
        />
      ),
    };
  }, [showModal]);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
  });

  function handleModalClose() {
    setShowModal(initialModalState);
  }

  function onSubmit(val) {
    const smsBundleData =
      bundleListing?.sms_bundle?.length > 0
        ? bundleListing?.sms_bundle?.find(
            (item) => item?.uuid === val?.sms_bundle,
          )
        : {};

    const callBundleData =
      bundleListing?.call_bundle?.length > 0
        ? bundleListing?.call_bundle?.find(
            (item) => item?.uuid === val?.call_bundle,
          )
        : {};

    setShowModal({
      modalType: dropdownActions.PURCHASE_BUNDLE,
      isShow: true,
      elementData: { ...val, smsBundleData, callBundleData },
    });
  }
  const { isShow, modalType } = showModal;

  const isLoadingBundleListing = useMemo(
    () => loadingBundleListing || fetchingBundleListing,
    [loadingBundleListing, fetchingBundleListing],
  );

  const hasBundles = Boolean(data?.call_bundle) || Boolean(data?.sms_bundle);

  return (
    <div className="overflow-auto w-full ">
      <div className="flex flex-col w-full">
        <div className="flex justify-between items-center w-100">
          <div className="text-sm text-grey-700">
            Manage bundle in your workspace
          </div>
          <div className=" w-64 mb-1">
            {purchaseBundleModal ? (
              <Button
                type="button"
                height="h-10"
                onClick={() => setPurchaseBundleModal(false)}
              >
                Back
              </Button>
            ) : (
              <Button
                type="button"
                height="h-10"
                onClick={() => setPurchaseBundleModal(true)}
              >
                Purchase bundle +
              </Button>
            )}
          </div>
        </div>
        {!data ? (
          purchaseBundleModal ? (
            <>
              <div className="w-full rounded-md p-7 border border-grey-400 bg-white min-h-[calc(100vh_-_226px)] flex justify-center">
                <div className="max-w-[800px] w-full flex flex-col gap-2">
                  {purchaseBundleModal ? (
                    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                      <div className="w-full">
                        <CountrySelect background="bg-grey-100" />
                      </div>

                      {!bundleListing?.sms_bundle?.length > 0 &&
                      !bundleListing?.call_bundle?.length > 0 &&
                      !isLoadingBundleListing ? (
                        <span className="flex items-center w-full text-center justify-center mt-3">
                          No bundle found
                        </span>
                      ) : (
                        <>
                          <div className="w-full mb-6">
                            <div className="text-lg font-semibold mt-4 mb-1">
                              Calls bundle
                            </div>
                            <div className="grid grid-cols-3 gap-4">
                              {isLoadingBundleListing ? (
                                <>
                                  {Array.from(
                                    { length: 3 },
                                    (_, i) => i + 1,
                                  ).map((val) => {
                                    return (
                                      <Skeletons key={val} height="h-20" />
                                    );
                                  })}
                                </>
                              ) : bundleListing?.call_bundle?.length > 0 ? (
                                bundleListing?.call_bundle?.map((val) => {
                                  return (
                                    <BundleItem
                                      key={val?.uuid}
                                      control={control}
                                      valueKey="call_bundle"
                                      itemData={val}
                                    />
                                  );
                                })
                              ) : (
                                <span className="flex items-center w-full text-center justify-center mt-3">
                                  No bundle found
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="w-full mb-6">
                            <div className="text-lg font-semibold mt-4 mb-1">
                              SMS bundle
                            </div>
                            <div className="grid grid-cols-3 gap-4">
                              {isLoadingBundleListing ? (
                                <>
                                  {Array.from(
                                    { length: 3 },
                                    (_, i) => i + 1,
                                  ).map((val) => {
                                    return (
                                      <Skeletons key={val} height="h-20" />
                                    );
                                  })}
                                </>
                              ) : bundleListing?.sms_bundle?.length > 0 ? (
                                bundleListing?.sms_bundle?.map((val) => {
                                  return (
                                    <BundleItem
                                      key={val}
                                      control={control}
                                      valueKey="sms_bundle"
                                      itemData={val}
                                    />
                                  );
                                })
                              ) : (
                                <span className="flex items-center w-full text-center justify-center mt-3">
                                  No bundle found
                                </span>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      <div className=" w-full flex justify-center mt-9">
                        <Button
                          type="submit"
                          border="border-green"
                          extraClasses="max-w-[400px]"
                          disabled={
                            !watch('call_bundle') && !watch('sms_bundle')
                          }
                        >
                          Purchase
                        </Button>
                      </div>
                    </form>
                  ) : isLoading ? (
                    <div className="max-w-[600px] w-full flex flex-col gap-2">
                      {Array.from({ length: 3 }, (_, i) => i + 1).map((val) => {
                        return (
                          <div className="w-full" key={val}>
                            <Skeletons height="h-14" />
                          </div>
                        );
                      })}
                    </div>
                  ) : hasBundles ? (
                    <>
                      {data?.call_bundle ? (
                        <div className="w-full mb-5 px-6">
                          <div className="text-lg font-semibold mt-4 mb-1">
                            Calls bundle
                          </div>
                          <div className="border border-grey-400 bg-grey-100 rounded-md p-3 flex items-center justify-between  w-full">
                            <div className="flex flex-col items-center justify-center gap-1  w-full">
                              <div className="text-xs text-grey-600 flex items-center gap-1">
                                <Billing className="w-4 h-4 rotate-90" />{' '}
                                Duration left
                              </div>
                              <div className="text-sm">
                                {SecondsTohhmmss(data?.call_bundle)}
                              </div>
                            </div>

                            <div className="flex flex-col items-center justify-center gap-1 w-full  border-l-2 border-grey-400">
                              <div className="text-xs text-grey-600 flex items-center  gap-1">
                                <Clock className="w-4 h-4 " /> Expiration
                              </div>
                              <div className="text-sm capitalize">
                                {
                                  formatDateTime(
                                    data?.call_bundle_expiry_date,
                                  )?.[0]
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {data?.sms_bundle ? (
                        <div className="w-full mb-5 px-6">
                          <div className="text-lg font-semibold mt-4 mb-1">
                            SMS bundle
                          </div>
                          <div className="border border-grey-400 bg-grey-100 rounded-md p-3 flex items-center justify-between  w-full">
                            <div className="flex flex-col items-center justify-center gap-1  w-full">
                              <div className="text-xs text-grey-600 flex items-center gap-1">
                                <Billing className="w-4 h-4 rotate-90" /> SMS
                                left
                              </div>
                              <div className="text-sm">{data?.sms_bundle}</div>
                            </div>

                            <div className="flex flex-col items-center justify-center gap-1 w-full   border-l-2 border-grey-400">
                              <div className="text-xs text-grey-600 flex items-center  gap-1">
                                <Clock className="w-4 h-4 " /> Expiration
                              </div>
                              <div className="text-sm capitalize">
                                {
                                  formatDateTime(
                                    data?.sms_bundle_expiry_date,
                                  )?.[0]
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                      <div className="w-full">
                        <CountrySelect background="bg-grey-100" />
                      </div>

                      {!bundleListing?.sms_bundle?.length > 0 &&
                      !bundleListing?.call_bundle?.length > 0 &&
                      !isLoadingBundleListing ? (
                        <span className="flex items-center w-full text-center justify-center mt-3">
                          No bundle found
                        </span>
                      ) : (
                        <>
                          <div className="w-full mb-6">
                            <div className="text-lg font-semibold mt-4 mb-1">
                              Calls bundle
                            </div>
                            <div className="grid grid-cols-3 gap-4">
                              {isLoadingBundleListing ? (
                                <>
                                  {Array.from(
                                    { length: 3 },
                                    (_, i) => i + 1,
                                  ).map((val) => {
                                    return (
                                      <Skeletons key={val} height="h-20" />
                                    );
                                  })}
                                </>
                              ) : bundleListing?.call_bundle?.length > 0 ? (
                                bundleListing?.call_bundle?.map((val) => {
                                  return (
                                    <BundleItem
                                      key={val?.uuid}
                                      control={control}
                                      valueKey="call_bundle"
                                      itemData={val}
                                    />
                                  );
                                })
                              ) : (
                                <span className="flex items-center w-full text-center justify-center mt-3">
                                  No bundle found
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="w-full mb-6">
                            <div className="text-lg font-semibold mt-4 mb-1">
                              SMS bundle
                            </div>
                            <div className="grid grid-cols-3 gap-4">
                              {isLoadingBundleListing ? (
                                <>
                                  {Array.from(
                                    { length: 3 },
                                    (_, i) => i + 1,
                                  ).map((val) => {
                                    return (
                                      <Skeletons key={val} height="h-20" />
                                    );
                                  })}
                                </>
                              ) : bundleListing?.sms_bundle?.length > 0 ? (
                                bundleListing?.sms_bundle?.map((val) => {
                                  return (
                                    <BundleItem
                                      key={val}
                                      control={control}
                                      valueKey="sms_bundle"
                                      itemData={val}
                                    />
                                  );
                                })
                              ) : (
                                <span className="flex items-center w-full text-center justify-center mt-3">
                                  No bundle found
                                </span>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      <div className=" w-full flex justify-center mt-9">
                        <Button
                          type="submit"
                          border="border-green"
                          extraClasses="max-w-[400px]"
                          disabled={
                            !watch('call_bundle') && !watch('sms_bundle')
                          }
                        >
                          Purchase
                        </Button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="w-full rounded-md p-7 border border-grey-400 bg-white min-h-[calc(100vh_-_226px)] flex justify-center">
              <div className="max-w-[800px] w-full flex flex-col gap-2">
                {user?.get_company_details?.call_bundle ? (
                  <div className="w-full mb-5 px-6">
                    <div className="text-lg font-semibold mt-4 mb-1">
                      Calls bundle
                    </div>
                    <div className="border border-grey-400 bg-grey-100 rounded-md p-3 flex items-center justify-between  w-full">
                      <div className="flex flex-col items-center justify-center gap-1  w-full">
                        <div className="text-xs text-grey-600 flex items-center gap-1">
                          <Billing className="w-4 h-4 rotate-90" /> Duration
                          left
                        </div>
                        <div className="text-sm">
                          {SecondsTohhmmss(
                            user?.get_company_details?.call_bundle,
                          )}
                        </div>
                      </div>

                      <div className="flex flex-col items-center justify-center gap-1 w-full  border-l-2 border-grey-400">
                        <div className="text-xs text-grey-600 flex items-center  gap-1">
                          <Clock className="w-4 h-4 " /> Expiration
                        </div>
                        <div className="text-sm capitalize">Trial</div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          )
        ) : (
          <>
            {/* <div className="flex items-center justify-between mb-5"> */}
            {/* <div className="text-sm text-grey-700">
                Manage bundle in your workspace
              </div> */}
            {/* {hasBundles ? (
                <div className=" w-64">
                  {purchaseBundleModal ? (
                    <Button
                      type="button"
                      height="h-10"
                      onClick={() => setPurchaseBundleModal(false)}
                    >
                      Back
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      height="h-10"
                      onClick={() => setPurchaseBundleModal(true)}
                    >
                      Purchase bundle +
                    </Button>
                  )}
                </div>
              ) : null} */}
            {/* </div> */}

            <div className="w-full rounded-md p-7 border border-grey-400 bg-white min-h-[calc(100vh_-_226px)] flex justify-center">
              <div className="max-w-[800px] w-full flex flex-col gap-2">
                {purchaseBundleModal ? (
                  <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-full">
                      <CountrySelect background="bg-grey-100" />
                    </div>

                    {!bundleListing?.sms_bundle?.length > 0 &&
                    !bundleListing?.call_bundle?.length > 0 &&
                    !isLoadingBundleListing ? (
                      <span className="flex items-center w-full text-center justify-center mt-3">
                        No bundle found
                      </span>
                    ) : (
                      <>
                        <div className="w-full mb-6">
                          <div className="text-lg font-semibold mt-4 mb-1">
                            Calls bundle
                          </div>
                          <div className="grid grid-cols-3 gap-4">
                            {isLoadingBundleListing ? (
                              <>
                                {Array.from({ length: 3 }, (_, i) => i + 1).map(
                                  (val) => {
                                    return (
                                      <Skeletons key={val} height="h-20" />
                                    );
                                  },
                                )}
                              </>
                            ) : bundleListing?.call_bundle?.length > 0 ? (
                              bundleListing?.call_bundle?.map((val) => {
                                return (
                                  <BundleItem
                                    key={val?.uuid}
                                    control={control}
                                    valueKey="call_bundle"
                                    itemData={val}
                                  />
                                );
                              })
                            ) : (
                              <span className="flex items-center w-full text-center justify-center mt-3">
                                No bundle found
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="w-full mb-6">
                          <div className="text-lg font-semibold mt-4 mb-1">
                            SMS bundle
                          </div>
                          <div className="grid grid-cols-3 gap-4">
                            {isLoadingBundleListing ? (
                              <>
                                {Array.from({ length: 3 }, (_, i) => i + 1).map(
                                  (val) => {
                                    return (
                                      <Skeletons key={val} height="h-20" />
                                    );
                                  },
                                )}
                              </>
                            ) : bundleListing?.sms_bundle?.length > 0 ? (
                              bundleListing?.sms_bundle?.map((val) => {
                                return (
                                  <BundleItem
                                    key={val}
                                    control={control}
                                    valueKey="sms_bundle"
                                    itemData={val}
                                  />
                                );
                              })
                            ) : (
                              <span className="flex items-center w-full text-center justify-center mt-3">
                                No bundle found
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    <div className=" w-full flex justify-center mt-9">
                      <Button
                        type="submit"
                        border="border-green"
                        extraClasses="max-w-[400px]"
                        disabled={!watch('call_bundle') && !watch('sms_bundle')}
                      >
                        Purchase
                      </Button>
                    </div>
                  </form>
                ) : isLoading ? (
                  <div className="max-w-[600px] w-full flex flex-col gap-2">
                    {Array.from({ length: 3 }, (_, i) => i + 1).map((val) => {
                      return (
                        <div className="w-full" key={val}>
                          <Skeletons height="h-14" />
                        </div>
                      );
                    })}
                  </div>
                ) : hasBundles ? (
                  <>
                    {data?.call_bundle ? (
                      <div className="w-full mb-5 px-6">
                        <div className="text-lg font-semibold mt-4 mb-1">
                          Calls bundle
                        </div>
                        <div className="border border-grey-400 bg-grey-100 rounded-md p-3 flex items-center justify-between  w-full">
                          <div className="flex flex-col items-center justify-center gap-1  w-full">
                            <div className="text-xs text-grey-600 flex items-center gap-1">
                              <Billing className="w-4 h-4 rotate-90" /> Duration
                              left
                            </div>
                            <div className="text-sm">
                              {SecondsTohhmmss(data?.call_bundle)}
                            </div>
                          </div>

                          <div className="flex flex-col items-center justify-center gap-1 w-full  border-l-2 border-grey-400">
                            <div className="text-xs text-grey-600 flex items-center  gap-1">
                              <Clock className="w-4 h-4 " /> Expiration
                            </div>
                            <div className="text-sm capitalize">
                              {
                                formatDateTime(
                                  data?.call_bundle_expiry_date,
                                )?.[0]
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {data?.sms_bundle ? (
                      <div className="w-full mb-5 px-6">
                        <div className="text-lg font-semibold mt-4 mb-1">
                          SMS bundle
                        </div>
                        <div className="border border-grey-400 bg-grey-100 rounded-md p-3 flex items-center justify-between  w-full">
                          <div className="flex flex-col items-center justify-center gap-1  w-full">
                            <div className="text-xs text-grey-600 flex items-center gap-1">
                              <Billing className="w-4 h-4 rotate-90" /> SMS left
                            </div>
                            <div className="text-sm">{data?.sms_bundle}</div>
                          </div>

                          <div className="flex flex-col items-center justify-center gap-1 w-full   border-l-2 border-grey-400">
                            <div className="text-xs text-grey-600 flex items-center  gap-1">
                              <Clock className="w-4 h-4 " /> Expiration
                            </div>
                            <div className="text-sm capitalize">
                              {
                                formatDateTime(
                                  data?.sms_bundle_expiry_date,
                                )?.[0]
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-full">
                      <CountrySelect background="bg-grey-100" />
                    </div>

                    {!bundleListing?.sms_bundle?.length > 0 &&
                    !bundleListing?.call_bundle?.length > 0 &&
                    !isLoadingBundleListing ? (
                      <span className="flex items-center w-full text-center justify-center mt-3">
                        No bundle found
                      </span>
                    ) : (
                      <>
                        <div className="w-full mb-6">
                          <div className="text-lg font-semibold mt-4 mb-1">
                            Calls bundle
                          </div>
                          <div className="grid grid-cols-3 gap-4">
                            {isLoadingBundleListing ? (
                              <>
                                {Array.from({ length: 3 }, (_, i) => i + 1).map(
                                  (val) => {
                                    return (
                                      <Skeletons key={val} height="h-20" />
                                    );
                                  },
                                )}
                              </>
                            ) : bundleListing?.call_bundle?.length > 0 ? (
                              bundleListing?.call_bundle?.map((val) => {
                                return (
                                  <BundleItem
                                    key={val?.uuid}
                                    control={control}
                                    valueKey="call_bundle"
                                    itemData={val}
                                  />
                                );
                              })
                            ) : (
                              <span className="flex items-center w-full text-center justify-center mt-3">
                                No bundle found
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="w-full mb-6">
                          <div className="text-lg font-semibold mt-4 mb-1">
                            SMS bundle
                          </div>
                          <div className="grid grid-cols-3 gap-4">
                            {isLoadingBundleListing ? (
                              <>
                                {Array.from({ length: 3 }, (_, i) => i + 1).map(
                                  (val) => {
                                    return (
                                      <Skeletons key={val} height="h-20" />
                                    );
                                  },
                                )}
                              </>
                            ) : bundleListing?.sms_bundle?.length > 0 ? (
                              bundleListing?.sms_bundle?.map((val) => {
                                return (
                                  <BundleItem
                                    key={val}
                                    control={control}
                                    valueKey="sms_bundle"
                                    itemData={val}
                                  />
                                );
                              })
                            ) : (
                              <span className="flex items-center w-full text-center justify-center mt-3">
                                No bundle found
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    <div className=" w-full flex justify-center mt-9">
                      <Button
                        type="submit"
                        border="border-green"
                        extraClasses="max-w-[400px]"
                        disabled={!watch('call_bundle') && !watch('sms_bundle')}
                      >
                        Purchase
                      </Button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {isShow ? (
        <Modal
          handleClose={handleModalClose}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
}

export default Bundle;
