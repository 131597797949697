import { useMutation, useQueryClient } from '@tanstack/react-query';
import { walletAutoTopup } from 'api';
import { toastEmitter } from 'components/Toast';

export default function useWalletAutoTopup({
  handleSuccess = () => null,
  handleError = () => null,
} = {}) {
  const queryClient = useQueryClient();
  const Request = useMutation({
    mutationFn: walletAutoTopup,
    mutationKey: ['walletAutoTopup'],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        handleError();
        return toastEmitter('error', responseData?.error?.message);
      }
      handleSuccess();
      queryClient.refetchQueries({
        queryKey: ['getWalletAmounts'],
        type: 'active',
        exact: true,
      });
      toastEmitter('success', responseData?.data?.message);
    },
  });
  return { ...Request };
}
